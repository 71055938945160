import { CheckCircle } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Button } from "reactstrap";

const CheckoutComplete = ({ open, handleClose, completePayment }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <CheckCircle /> Checkout completed
      </DialogTitle>
      <DialogContent>
        <DialogContentText></DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={completePayment} autoFocus>
          Pay Now
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CheckoutComplete;
