import { CircularProgress, LinearProgress } from "@mui/material";

const GradientLinearProgress = (props) => {
  return (
    <>
      <svg width={0} height={0}>
        <defs>
          <linearGradient
            id="progress-bar-gradient"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop offset="0%" stopColor="#e01cd5" />
            <stop offset="100%" stopColor="#1CB5E0" />
          </linearGradient>
        </defs>
      </svg>
      <LinearProgress
        variant="determinate"
        sx={{ "svg circle": { fill: "url(#progress-bar-gradient)" } }}
        {...props}
      />
    </>
  );
};

export default GradientLinearProgress;
