import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ERROR_MESSAGES,
  LOADING_DIALOGS,
  NOTIFICATIONS,
  VARIABLES,
} from "../util/constants";
import axios from "axios";
import {
  getPublicKey,
  postGoogleOAuth,
  postLogin,
  putUser,
} from "../api/requests";
import {
  addNotification,
  setProcessProcessing as setPageProcessing,
} from "./ProcessReducer";
import {
  decodeProtectedHeader,
  importPKCS8,
  importSPKI,
  jwtVerify,
} from "jose";
import { pki } from "node-forge";
import { router } from "../routes";

const authorizeUser = createAsyncThunk(
  "auth/authorizeUser",
  async (_, { dispatch }) => {
    dispatch(setPageProcessing(LOADING_DIALOGS.AUTHORIZING));
    try {
      let token =
        localStorage.getItem(VARIABLES.LOCAL_STORAGE_AUTH_TOKEN) ??
        sessionStorage.getItem(VARIABLES.LOCAL_STORAGE_AUTH_TOKEN);
      if (token) {
        axios.defaults.headers.common = {
          Authorization: "Bearer " + token,
        };

        let publicKey = localStorage.getItem(
          VARIABLES.LOCAL_STORAGE_PUBLIC_KEY
        );
        if (!publicKey) {
          const { payload } = await dispatch(updatePublicKey());
          publicKey = payload;
        }
        if (publicKey.startsWith("-----BEGIN RSA PUBLIC KEY-----")) {
          const key = pki.publicKeyToPem(pki.publicKeyFromPem(publicKey));
          const { alg } = decodeProtectedHeader(token);
          publicKey = await importSPKI(key, alg);
        } else publicKey = await importPKCS8(publicKey);

        dispatch(setPageProcessing(false));
        if (!publicKey) {
          return false;
        }
        return jwtVerify(token, publicKey);
      }
      dispatch(setPageProcessing(false));
    } catch (error) {
      dispatch(setPageProcessing(false));
      throw error;
    }
  }
);

const updatePublicKey = createAsyncThunk(
  "auth/updatePublicKey",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      let { status, data } = await getPublicKey();
      if (status === 200) {
        return data;
      } else {
        dispatch(
          addNotification({
            message: ERROR_MESSAGES.PUBLIC_KEY_FETCHING_ERROR,
            type: "error",
          })
        );
        rejectWithValue(data);
      }
    } catch (error) {
      dispatch(
        addNotification({
          message: ERROR_MESSAGES[error.response.status] ?? error.message,
          type: "error",
        })
      );
    }

    return false;
  }
);

const userLogin = createAsyncThunk(
  "auth/login",
  async ({ data: formData, shouldRemember }, { dispatch, rejectWithValue }) => {
    dispatch(setPageProcessing("Logging In..."));
    try {
      let { data } = await postLogin(formData);
      const { accessToken } = data || {};
      if (accessToken) {
        (shouldRemember ? localStorage : sessionStorage).setItem(
          VARIABLES.LOCAL_STORAGE_AUTH_TOKEN,
          accessToken
        );
      }
      router.navigate("/");
      return data;
    } catch (error) {
      const { message } = error.response?.data || error;
      if (typeof message === "object") {
        message.forEach((message) => {
          if (message.description) {
            dispatch(
              addNotification({
                message: message.description,
                type: "error",
              })
            );
          }
        });
      } else {
        dispatch(
          addNotification({
            message: message ?? "Error",
            type: "error",
          })
        );
      }
      dispatch(setPageProcessing());
      rejectWithValue(error);
    }
  }
);

const userGoogleOAuth = createAsyncThunk(
  "auth/userGoogleOAuth",
  async ({ data: formData, shouldRemember }, { dispatch, rejectWithValue }) => {
    dispatch(setPageProcessing("Logging In..."));
    try {
      let { data } = await postGoogleOAuth(formData);
      const { accessToken } = data || {};
      if (accessToken) {
        (shouldRemember ? localStorage : sessionStorage).setItem(
          VARIABLES.LOCAL_STORAGE_AUTH_TOKEN,
          accessToken
        );
      }
      router.navigate("/");
      return data;
    } catch (error) {
      const { message } = error.response?.data || error;
      if (typeof message === "object") {
        message.forEach((message) => {
          if (message.description) {
            dispatch(
              addNotification({
                message: message.description,
                type: "error",
              })
            );
          }
        });
      } else {
        dispatch(
          addNotification({
            message: message ?? "Error",
            type: "error",
          })
        );
      }
      dispatch(setPageProcessing());
      rejectWithValue(error);
    }
  }
);

const userRegister = createAsyncThunk(
  "auth/register",
  async (formData, { dispatch, rejectWithValue }) => {
    dispatch(setPageProcessing("Registering..."));
    try {
      let { data } = await putUser(formData);
      dispatch(setPageProcessing(false));
      dispatch(
        addNotification({
          message: NOTIFICATIONS.REGISTER_SUCCESS,
          type: "success",
        })
      );
      router.navigate("/login");
      return data;
    } catch (error) {
      const { message } = error.response?.data || error;
      if (typeof message === "object") {
        message.forEach((message) => {
          if (message.description) {
            dispatch(
              addNotification({
                message: message.description,
                type: "error",
              })
            );
          }
        });
      } else {
        dispatch(
          addNotification({
            message,
            type: "error",
          })
        );
      }
      dispatch(setPageProcessing());
      rejectWithValue(error);
    }
  }
);

const initialState = {
  isProcessing: false,
  user: null,
  isAuthenticated: false,
};

const slice = createSlice({
  name: "AuthSlice",
  initialState: initialState,
  reducers: {
    setAuthProcessing: (state, { payload }) => {
      state.isProcessing = payload;
    },
    updateUser: (state, { payload }) => {
      state.user = { ...state.user, ...payload };
    },
    logout: (state) => {
      state.user = undefined;
      state.isAuthenticated = false;
      localStorage.removeItem(VARIABLES.LOCAL_STORAGE_AUTH_TOKEN);
      sessionStorage.removeItem(VARIABLES.LOCAL_STORAGE_AUTH_TOKEN);
      localStorage.removeItem(VARIABLES.LOCAL_STORAGE_PUBLIC_KEY);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authorizeUser.pending, (state) => {
        state.isProcessing = true;
      })
      .addCase(authorizeUser.rejected, (state, { payload, error }) => {
        localStorage.removeItem(VARIABLES.LOCAL_STORAGE_AUTH_TOKEN);
        sessionStorage.removeItem(VARIABLES.LOCAL_STORAGE_AUTH_TOKEN);
        localStorage.removeItem(VARIABLES.LOCAL_STORAGE_PUBLIC_KEY);
        state.isProcessing = false;
      })
      .addCase(authorizeUser.fulfilled, (state, { payload, error }) => {
        if (payload) {
          state.user = payload.payload;
          state.isAuthenticated = true;
        }
        state.isProcessing = false;
      })
      .addCase(updatePublicKey.fulfilled, (state, { payload }) => {
        if (payload)
          localStorage.setItem(VARIABLES.LOCAL_STORAGE_PUBLIC_KEY, payload);
      })
      .addCase(
        userLogin.fulfilled,
        (state, { payload: { accessToken } = {} }) => {}
      )
      .addCase(userRegister.rejected, (state, _) => {
        console.error(_);
      });
  },
});

export {
  authorizeUser,
  updatePublicKey,
  userRegister,
  userLogin,
  userGoogleOAuth,
};
export const { logout, setAuthProcessing, updateUser } = slice.actions;

export default slice.reducer;
