import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addNotification } from "./ProcessReducer";
import { addOrder, getOrders, removeOrder } from "../api/requests";
import { clearCart } from "./CartReducer";

const getAllOrders = createAsyncThunk(
  "order/getOrders",
  async (_, { dispatch, rejectWithValue, getState }) => {
    dispatch(setIsProcessing(true));
    try {
      const { orders } = getState().cart;

      if (!orders || orders.length === 0) {
        let { data } = await getOrders();
        dispatch(setIsProcessing(false));
        return data;
      } else return orders;
    } catch (error) {
      dispatch(setIsProcessing(false));
      const { message } = error.response?.data || error;
      dispatch(
        addNotification({
          message: message ?? error.response?.data ?? error,
          type: "error",
        })
      );
      rejectWithValue(error);
    }
  }
);

const checkout = createAsyncThunk(
  "order/checkout",
  async (cart, { dispatch, rejectWithValue, getState }) => {
    dispatch(setIsProcessing(true));
    try {
      let { data } = await addOrder(cart);
      dispatch(setIsProcessing(false));
      dispatch(clearCart());
      return data;
    } catch (error) {
      dispatch(setIsProcessing(false));
      const { message } = error.response?.data || error;
      dispatch(
        addNotification({
          message: message ?? error.response?.data ?? error,
          type: "error",
        })
      );
      rejectWithValue(error);
    }
  }
);

const deleteOrder = createAsyncThunk(
  "order/deleteOrder",
  async (id, { dispatch, rejectWithValue, getState }) => {
    dispatch(setIsProcessing(true));
    try {
      await removeOrder(id);
      dispatch(setIsProcessing(false));
      return id;
    } catch (error) {
      dispatch(setIsProcessing(false));
      const { message } = error.response?.data || error;
      dispatch(
        addNotification({
          message: message ?? error.response?.data ?? error,
          type: "error",
        })
      );
      rejectWithValue(error);
    }
  }
);

const initialState = {
  isProcessing: false,
  orders: [],
};

const slice = createSlice({
  name: "OrderSlice",
  initialState: initialState,
  reducers: {
    setIsProcessing: (state, { payload }) => {
      state.isProcessing = payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getAllOrders.fulfilled, (state, { payload = [] }) => {
        state.orders = payload;
      })
      .addCase(checkout.fulfilled, (state, { payload }) => {
        state.orders?.push(payload);
      })
      .addCase(deleteOrder.fulfilled, (state, { payload }) => {
        state.orders =
          state.orders?.filter((order) => order.id !== payload) ?? [];
      }),
});

const { setIsProcessing } = slice.actions;

export { getAllOrders, checkout, deleteOrder };

export default slice.reducer;
