import { createSlice } from "@reduxjs/toolkit";
import { DESIGN_PRICE } from "../util/constants";

const initialState = {
  isProcessing: false,
  cart: JSON.parse(localStorage.getItem("cart")) ?? [],
  price: parseInt(localStorage.getItem("price") ?? "0"),
};

const slice = createSlice({
  name: "CartSlice",
  initialState: initialState,
  reducers: {
    setIsProcessing: (state, { payload }) => {
      state.isProcessing = payload;
    },
    clearCart: (state) => {
      state.cart = [];
      state.price = 0;

      localStorage.setItem("cart", JSON.stringify(state.cart));
      localStorage.setItem("price", JSON.stringify(state.price));
    },
    addItemToCart: (state, { payload }) => {
      let id = state.cart.length + 1;
      state.cart = [{ itemId: id, ...payload }, ...state.cart];
      state.price += payload.item.basePrice * payload.quantity;

      localStorage.setItem("cart", JSON.stringify(state.cart));
      localStorage.setItem("price", JSON.stringify(state.price));
    },
    updateItemInCart: (state, { payload: { id, cartItem } }) => {
      let item = state.cart.find(({ itemId }) => itemId === id);
      if (item) {
        state.price -=
          item.item.basePrice * item.quantity +
          (item.design ? DESIGN_PRICE : 0);
        let arr = state.cart.filter(({ itemId }) => itemId !== id);
        state.price +=
          cartItem.item.basePrice * cartItem.quantity +
          (cartItem.design ? DESIGN_PRICE : 0);
        state.cart = [cartItem, ...arr];
      }

      localStorage.setItem("cart", JSON.stringify(state.cart));
      localStorage.setItem("price", JSON.stringify(state.price));
    },
    deleteItemFromCart: (state, { payload }) => {
      let cartItem = state.cart.find(({ itemId }) => itemId === payload);
      state.price -=
        cartItem.item.basePrice * cartItem.quantity +
        (cartItem.design ? DESIGN_PRICE : 0);
      state.cart = state.cart.filter(({ itemId }) => itemId !== payload);

      localStorage.setItem("cart", JSON.stringify(state.cart));
      localStorage.setItem("price", JSON.stringify(state.price));
    },
  },
});

export const {
  setIsProcessing,
  clearCart,
  addItemToCart,
  deleteItemFromCart,
  updateItemInCart,
} = slice.actions;

export default slice.reducer;
