import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { authorizeUser, userGoogleOAuth } from "../slices/AuthReducer";
import { enqueueSnackbar } from "notistack";
import { useCallback } from "react";

const GoogleOAuth = ({ shouldRemember }) => {
  const dispatch = useDispatch();

  const onGoogleSignInComplete = useCallback(
    (data) => {
      dispatch(userGoogleOAuth({ data, shouldRemember })).then(() => {
        dispatch(authorizeUser());
      });
    },
    [dispatch, shouldRemember]
  );

  const onGoogleSignInFailed = useCallback((message) => {
    enqueueSnackbar(
      typeof message === "string" ? message : JSON.stringify(message),
      { variant: "error" }
    );
  }, []);

  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      onScriptLoadSuccess={console.log}
      onScriptLoadError={console.error}
    >
      <GoogleLogin
        size="large"
        onSuccess={onGoogleSignInComplete}
        onError={onGoogleSignInFailed}
      />
    </GoogleOAuthProvider>
  );
};

export default GoogleOAuth;
