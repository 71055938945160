import {
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { SERVER, STATIC_URI } from "../api/api_routes";
import { Delete, Download } from "@mui/icons-material";
import { useCallback } from "react";
import { executeDeleteDesign } from "../slices/DesignReducer";
import { useDispatch } from "react-redux";

const DesignComponent = ({ name, source, previews, id, aspectRatio }) => {
  const dispatch = useDispatch();

  const deleteDesign = useCallback(
    (e) => {
      let id = e.currentTarget.getAttribute("data-id");
      dispatch(executeDeleteDesign(id));
    },
    [dispatch]
  );

  return (
    <Card
      variant="outlined"
      elevation={1}
      sx={{
        m: "15px",
      }}
    >
      {previews?.[0] && (
        <CardMedia
          component="img"
          alt="Preview"
          height={"300px"}
          src={`${STATIC_URI}${previews[0]}`}
        />
      )}
      <CardContent sx={{ paddingBottom: "16px !important" }}>
        <Box>
          <Box>
            <Typography variant="body1" fontWeight={700} width="fit-content">
              {name}
            </Typography>
            <Typography variant="body2" width="fit-content">
              {aspectRatio}
            </Typography>
          </Box>
          <Box className="mb-2" display={"flex"} sx={{ float: "right" }}>
            <IconButton
              LinkComponent={Link}
              dowload="true"
              href={`${STATIC_URI}${source}`}
            >
              <Download />
            </IconButton>
            <IconButton data-id={id} dowload="true" onClick={deleteDesign}>
              <Delete />
            </IconButton>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default DesignComponent;
