import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrders } from "../slices/OrderReducer";
import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  List,
  ListItem,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Table } from "reactstrap";
import { Delete } from "@mui/icons-material";
import OrderRow from "../components/OrderRow";
import Require from "../components/Required";
import BackTracker from "../components/BackTracker";

const Orders = () => {
  const dispatch = useDispatch();

  const { isAuthenticated, isProcessing } = useSelector((state) => state.auth);
  const { orders, isProcessing: isOrdersProcessing } = useSelector(
    (state) => state.orders
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const visibleRows = useMemo(() => {
    return (
      orders?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) ?? []
    );
  }, [orders, page, rowsPerPage]);

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  useEffect(() => {
    if (isAuthenticated) dispatch(getAllOrders());
  }, [isAuthenticated]);

  return (
    !isProcessing && (
      <Require authorized={isAuthenticated} render="Page not found">
        <Container>
          <Box>
            <BackTracker end={"Orders"} />
          </Box>
          <Box>
            {isOrdersProcessing ? (
              <CircularProgress sx={{ mb: "10px" }} />
            ) : orders && orders?.[0] ? (
              <Box>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontFamily: "Poppins",
                            backgroundColor: "#eee !important",
                          }}
                          align="center"
                        >
                          Products
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "Poppins",
                            backgroundColor: "#eee !important",
                          }}
                          align="center"
                        >
                          Price
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "Poppins",
                            backgroundColor: "#eee !important",
                          }}
                          align="center"
                        >
                          Status
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "Poppins",
                            backgroundColor: "#eee !important",
                          }}
                          align="center"
                        >
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {visibleRows?.map((order, index) => (
                        <OrderRow key={index} order={order} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  className="table-pagination"
                  rowsPerPageOptions={[5, 10, 15]}
                  component="div"
                  count={orders?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            ) : (
              <Typography textAlign={"center"}>
                No previous orders were found for this account.
              </Typography>
            )}
          </Box>
        </Container>
      </Require>
    )
  );
};

export default Orders;
