import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addNotification } from "./ProcessReducer";
import { uploadDesignFile } from "../api/requests";

const uploadDesign = createAsyncThunk(
  "design/upload",
  async (formData, { dispatch, rejectWithValue }) => {
    try {
      let { data } = await uploadDesignFile(formData, ({ progress }) => {
        dispatch(setUploadProgress(progress));
      });
      setTimeout(() => {
        dispatch(setUploadProgress(false));
      }, 200);
      return data;
    } catch (error) {
      const { message } = error.response?.data || error;
      dispatch(
        addNotification({
          message: message ?? error.response?.data ?? error,
          type: "error",
        })
      );
      rejectWithValue(error);
    }
  }
);

const initialState = {
  uploadProgress: false,
  isProcessing: false,
  designs: JSON.parse(localStorage.getItem("designs") ?? "[]"),
};

const slice = createSlice({
  name: "DesignSlice",
  initialState: initialState,
  reducers: {
    setUploadProgress: (state, { payload }) => {
      state.uploadProgress = payload;
    },
    setProcessing: (state, { payload }) => {
      state.uploadProgress = payload;
    },
    executeDeleteDesign: (state, { payload }) => {
      state.designs = state.designs?.filter((design) => design.id != payload);

      localStorage.setItem("designs", JSON.stringify(state.designs));
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(uploadDesign.fulfilled, (state, { payload }) => {
        if (payload) {
          state.designs = [
            { id: state.designs.length, ...payload },
            ...state.designs,
          ];

          localStorage.setItem("designs", JSON.stringify(state.designs));
        }
      })
      .addCase(uploadDesign.rejected, (state, { payload = [] }) => {}),
});

export const { setUploadProgress, setProcessing, executeDeleteDesign } =
  slice.actions;

export { uploadDesign };

export default slice.reducer;
