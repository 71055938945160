import axios from "axios";
import {
  AUTH,
  CART,
  DESIGN,
  ORDER,
  PRODUCT_CATEGORY,
  PROFILE,
  SUPPORT,
} from "./api_routes";

const headers = { "Content-Type": "application/json" };

const getPublicKey = async () => {
  const api = AUTH.GET_PUBLIC_KEY;
  let res = await axios({ url: api.url, method: api.method });
  return { status: res.status, data: res.data };
};

const postLogin = async (formData) => {
  const { url, method } = AUTH.LOGIN;
  return await axios({
    url,
    method,
    data: formData,
    headers,
  });
};

const postGoogleOAuth = async (data) => {
  const { url, method } = AUTH.GOOGLE_OAUTH;
  return await axios({
    url,
    method,
    data: data,
    headers,
  });
};

const putUser = async (formData) => {
  const { url, method } = AUTH.REGISTER;
  try {
    return await axios({
      url,
      method,
      data: formData,
      headers,
    });
  } catch (error) {
    throw error;
  }
};

const fetchProductCategories = async () => {
  const { url, method } = PRODUCT_CATEGORY.GET_PRODCUT_CATEGORIES;
  try {
    return await axios({
      url,
      method,
      headers,
    });
  } catch (error) {
    throw error;
  }
};

const uploadDesignFile = async (formData, onProgress, onComplete) => {
  const { url, method } = DESIGN.UPLOAD;
  try {
    return await axios({
      url,
      method,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
      onUploadProgress: onProgress,
    });
  } catch (error) {
    throw error;
  }
};
const fetchDesigns = async () => {
  const { url, method } = DESIGN.GET_ALL;
  try {
    return await axios({
      url,
      method,
      headers,
    });
  } catch (error) {
    throw error;
  }
};

const deleteDesign = async (id) => {
  const { url, method } = DESIGN.DELETE;
  try {
    return await axios({
      url,
      method,
      headers,
      params: { id },
    });
  } catch (error) {
    throw error;
  }
};

const getCart = async () => {
  const { url, method } = CART.GET;
  try {
    return await axios({
      url,
      method,
      headers,
    });
  } catch (error) {
    throw error;
  }
};
const postProfile = async (data) => {
  const { url, method } = PROFILE.UPDATE;
  try {
    return await axios({
      url,
      method,
      headers,
      data,
    });
  } catch (error) {
    throw error;
  }
};

const addToCart = async (formData) => {
  const { url, method } = CART.ADD;
  try {
    return await axios({
      url,
      method,
      headers,
      data: formData,
    });
  } catch (error) {
    throw error;
  }
};

const removeFromCart = async (id) => {
  const { url, method } = CART.DELETE;
  try {
    return await axios({
      url,
      method,
      headers,
      params: { id },
    });
  } catch (error) {
    throw error;
  }
};

const getOrders = async () => {
  const { url, method } = ORDER.GET;
  try {
    return await axios({
      url,
      method,
      headers,
    });
  } catch (error) {
    throw error;
  }
};

const addOrder = async (data) => {
  const { url, method } = ORDER.ADD;
  try {
    return await axios({
      url,
      method,
      headers,
      data,
    });
  } catch (error) {
    throw error;
  }
};

const removeOrder = async (id) => {
  const { url, method } = ORDER.DELETE;
  try {
    return await axios({
      url,
      method,
      headers,
      params: { id },
    });
  } catch (error) {
    throw error;
  }
};

const sendSupportMessage = async (data) => {
  const { url, method } = SUPPORT.MESSAGE;
  try {
    return await axios({
      url,
      method,
      headers,
      data,
    });
  } catch (error) {
    throw error;
  }
};

export {
  getPublicKey,
  postLogin,
  putUser,
  fetchProductCategories,
  uploadDesignFile,
  fetchDesigns,
  deleteDesign,
  getCart,
  addToCart,
  removeFromCart,
  getOrders,
  addOrder,
  removeOrder,
  postGoogleOAuth,
  postProfile,
  sendSupportMessage,
};
