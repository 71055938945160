const VARIABLES = {
  LOCAL_STORAGE_AUTH_TOKEN: "_authToken",
  LOCAL_STORAGE_PUBLIC_KEY: "_publicKey",
};

const LOADING_DIALOGS = {
  AUTHORIZING: "Authorizing...",
};

const NOTIFICATIONS = {
  REGISTER_SUCCESS: "Successfully created account.",
};

const ERROR_MESSAGES = {
  PUBLIC_KEY_FETCHING_ERROR: "Failed to retrieve public key from server",
  401: "Unauthorized",
  500: "Internal server error",
};

const DESIGN_PRICE = 10;

export {
  VARIABLES,
  LOADING_DIALOGS,
  ERROR_MESSAGES,
  NOTIFICATIONS,
  DESIGN_PRICE,
};
