import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { router } from "../routes";
import { useDispatch, useSelector } from "react-redux";
import { authorizeUser, userLogin } from "../slices/AuthReducer";
import {
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Require from "../components/Required";
import Copyright from "../components/Copyright";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { enqueueSnackbar } from "notistack";
import GoogleOAuth from "../components/GoogleOAuth";

const Login = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = React.useState(false);
  const [shouldRemember, setShouldRemember] = React.useState(false);
  const [emailFilled, setEmailFilled] = React.useState([false, true]);
  const [passwordFilled, setPasswordFilled] = React.useState([false, true]);

  const onFocusField = React.useCallback(
    (e) => {
      const { name } = e.target;
      switch (name) {
        case "email":
          setEmailFilled([emailFilled[0], false]);
          break;
        case "password":
          setPasswordFilled([passwordFilled[0], false]);
          break;
        default:
      }
    },
    [emailFilled, passwordFilled]
  );

  const onChangeField = React.useCallback(
    (e) => {
      const { name, value } = e.target;
      switch (name) {
        case "email":
          setEmailFilled([value.match(/^.{3,}@\w+\.+\w+$/), emailFilled[1]]);
          break;
        case "password":
          setPasswordFilled([value.match(/.{8,}/), passwordFilled[1]]);
          break;
        case "remember":
          setShouldRemember(e.target.checked);
          break;
        default:
      }
    },
    [emailFilled, passwordFilled]
  );
  const passwordVisibilityChanged = React.useCallback(() => {
    setShowPassword((state) => !state);
  }, []);

  const navigateToRegister = React.useCallback(() => {
    router.navigate("/register");
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    dispatch(userLogin({ data, shouldRemember })).then(() => {
      dispatch(authorizeUser());
    });
  };

  return (
    <Require authorized={!isAuthenticated} redirect={"/home"}>
      <Container className="position-relative" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              error={!emailFilled[1] && !emailFilled[0]}
              onFocus={onFocusField}
              onChange={onChangeField}
            />
            <FormControl fullWidth variant="outlined">
              <InputLabel
                htmlFor="outlined-password"
                error={!passwordFilled[1] && !passwordFilled[0]}
              >
                Password
              </InputLabel>
              <OutlinedInput
                required
                label="Password"
                fullWidth
                id="outlined-password"
                name="password"
                type={showPassword ? "text" : "password"}
                error={!passwordFilled[1] && !passwordFilled[0]}
                onFocus={onFocusField}
                onChange={onChangeField}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={passwordVisibilityChanged}
                      onMouseDown={passwordVisibilityChanged}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox value="remember" color="primary" name="remember" />
              }
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, color: "white" }}
              disabled={!passwordFilled[0] || !emailFilled[0]}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs></Grid>
              <Grid item>
                <Link
                  onClick={navigateToRegister}
                  component="button"
                  href="#"
                  variant="body2"
                >
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box sx={{ top: 0, right: 0 }}>
          <Divider className="my-2" sx={{ fontFamily: "Poppins" }}>
            SSO
          </Divider>
          <Box className="d-flex align-items-center justify-content-center">
            <GoogleOAuth shouldRemember={shouldRemember} />
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </Require>
  );
};

export default Login;
