import { useEffect } from "react";
import { router } from "../routes";
import { Box } from "@mui/material";

const Require = ({
  roles,
  currentRole,
  authorized,
  redirect,
  children,
  render,
}) => {
  useEffect(() => {
    if (
      redirect &&
      (!authorized || (roles ? !roles.contains(currentRole) : false))
    ) {
      router.navigate(redirect);
    }
  }, [authorized, redirect, currentRole, roles]);

  return authorized && (roles ? roles.contains(currentRole) : true) ? (
    children
  ) : (
    <Box textAlign="center">{render}</Box>
  );
};

export default Require;
