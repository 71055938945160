import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  FormControl,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material";
import BackTracker from "../components/BackTracker";
import { useState } from "react";
import { sendSupportMessage } from "../api/requests";
import { addNotification } from "../slices/ProcessReducer";
import { useDispatch } from "react-redux";

const Support = () => {
  const dispatch = useDispatch();

  const [isUploading, setIsUploading] = useState(false);
  const [name, setName] = useState(false);
  const [email, setEmail] = useState(false);
  const [message, setMessage] = useState(false);

  const isNameError = name === "";
  const isEmailError =
    email === "" || (email && !email?.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/));
  const isMessageError = message === "";

  const onInputChange = (e) => {
    let { name, value } = e.currentTarget;

    switch (name) {
      case "name":
        setName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "message":
        setMessage(value);
        break;
      default:
        break;
    }
  };

  const onUpload = async () => {
    let data = new FormData();
    data.append("name", name);
    data.append("email", email);
    data.append("message", message);

    setIsUploading(true);
    sendSupportMessage(data)
      .catch((err) => {
        console.error(err);
        dispatch(
          addNotification({
            message: "Error sending message",
            type: "error",
          })
        );
      })
      .then(() => {
        dispatch(
          addNotification({
            message:
              "Message send successfully. Someone will contact you soon.",
            type: "success",
          })
        );
        setIsUploading(false);
      });
  };

  return (
    <Container>
      <Box>
        <BackTracker end={"Support"} />
      </Box>

      <Typography variant="h2" fontFamily={"Poppins"} textAlign={"center"}>
        Having any problems ?
      </Typography>

      <Typography my={"8px"} textAlign={"center"}>
        We're here to help. contact us anytime.
      </Typography>

      <Card variant="outlined" component={"form"}>
        <Box className="d-flex flex-column" p={"25px 15px"}>
          <FormControl>
            <FormLabel htmlFor="name">Fullname</FormLabel>
            <TextField
              disabled={isUploading}
              variant="outlined"
              autoComplete="name"
              name="name"
              required
              size="small"
              id="name"
              color={isNameError ? "error" : "primary"}
              onChange={onInputChange}
              error={isNameError}
              helperText={isNameError && "Please insert your name."}
            />
          </FormControl>
          <FormControl className="mt-3">
            <FormLabel htmlFor="email">Email Address</FormLabel>
            <TextField
              disabled={isUploading}
              required
              id="email"
              size="small"
              name="email"
              autoComplete="email"
              variant="outlined"
              color={isEmailError ? "error" : "primary"}
              onChange={onInputChange}
              error={isEmailError}
              helperText={
                isEmailError && "Please insert your correct email address."
              }
            />
          </FormControl>
          <FormControl className="mt-3">
            <FormLabel htmlFor="message">Message</FormLabel>
            <TextField
              disabled={isUploading}
              className="form-control"
              rows={3}
              required
              id="message"
              multiline
              name="message"
              color={isMessageError ? "error" : "primary"}
              onChange={onInputChange}
              error={isMessageError}
              helperText={isMessageError && "Message can't be empty"}
            />
          </FormControl>
          {isUploading && (
            <CircularProgress
              sx={{
                alignSelf: "flex-end",
                m: "10px 35px",
              }}
            />
          )}
          <Button
            disabled={
              isUploading ||
              !name ||
              isNameError ||
              !message ||
              isMessageError ||
              !email ||
              isEmailError
            }
            sx={{
              width: "fit-content",
              color: "white",
              m: "10px",
              alignSelf: "flex-end",
            }}
            type="button"
            variant="contained"
            onClick={onUpload}
          >
            Submit
          </Button>
        </Box>
      </Card>
    </Container>
  );
};

export default Support;
