import { configureStore } from "@reduxjs/toolkit";
import ProcessReducer from "./slices/ProcessReducer";
import AuthReducer from "./slices/AuthReducer";
import ProductReducer from "./slices/ProductReducer";
import DesignReducer from "./slices/DesignReducer";
import CartReducer from "./slices/CartReducer";
import OrderReducer from "./slices/OrderReducer";
import ProfileReducer from "./slices/ProfileReducer";

const store = configureStore({
  reducer: {
    process: ProcessReducer,
    auth: AuthReducer,
    product: ProductReducer,
    design: DesignReducer,
    cart: CartReducer,
    orders: OrderReducer,
    profile: ProfileReducer,
  },
});

export default store;
