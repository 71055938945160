import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postProfile } from "../api/requests";
import { addNotification } from "./ProcessReducer";
import { VARIABLES } from "../util/constants";
import { authorizeUser } from "./AuthReducer";

const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  async (formData, { dispatch, rejectWithValue, getState }) => {
    dispatch(setIsProcessing(true));
    try {
      let { data } = await postProfile(formData);
      const { accessToken } = data || {};
      if (accessToken) {
        localStorage.setItem(VARIABLES.LOCAL_STORAGE_AUTH_TOKEN, accessToken);
      }
      dispatch(authorizeUser());
      dispatch(setIsProcessing(false));
      return accessToken;
    } catch (error) {
      dispatch(setIsProcessing(false));
      const { message } = error.response?.data || error;
      dispatch(
        addNotification({
          message: message ?? error.response?.data ?? error,
          type: "error",
        })
      );
      rejectWithValue(error);
    }
  }
);

const initialState = {
  isProcessing: false,
};

const slice = createSlice({
  name: "OrderSlice",
  initialState: initialState,
  reducers: {
    setIsProcessing: (state, { payload }) => {
      state.isProcessing = payload;
    },
  },
});

const { setIsProcessing } = slice.actions;

export { updateProfile };

export default slice.reducer;
