import Navbar from "./Navbar";
import { SnackbarProvider } from "notistack";
import Main from "./Main";
import { useDispatch } from "react-redux";
import { createRef, useEffect, useState } from "react";
import { authorizeUser } from "../slices/AuthReducer";
import Footer from "./Footer";

const Layout = ({ children }) => {
  const dispatch = useDispatch();

  const [footer, setFooter] = useState();

  useEffect(() => {
    dispatch(authorizeUser());
  }, []);

  return (
    <div
      className="page"
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Navbar />
      <SnackbarProvider
        autoHideDuration={2000}
        maxSnack={5}
        style={{ fontFamily: "Poppins" }}
      >
        <Main sx={{ flexGrow: 1 }}>{children}</Main>
      </SnackbarProvider>
      <Footer ref={setFooter} />
    </div>
  );
};

export default Layout;
