import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Paper,
  Radio,
  Select,
  Skeleton,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductCategories } from "../slices/ProductReducer";
import { Link, useParams } from "react-router-dom";
import { router } from "../routes";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ProductCategoryBox from "../components/ProductCategoryBox";
import {
  ArrowForwardIos,
  Cancel,
  Close,
  CloseRounded,
  Delete,
  ExpandMore,
} from "@mui/icons-material";
import BpCheckbox from "../components/mui/BpCheckBox";
import Image03 from "../images/img_03.png";
import BackTracker from "../components/BackTracker";
import {
  MuiAccordion,
  MuiAccordionDetails,
  MuiAccordionSummary,
} from "../components/mui/MuiAccordion";

const ProductCategory = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { categories, isProductCategoryProcessing } = useSelector(
    (state) => state.product
  );

  const [selectedCategory, setSelectedCategory] = useState();

  useEffect(() => {
    let categoryId = parseInt(id);
    setSelectedCategory(categories?.find(({ id }) => categoryId === id));
  }, [categories, id]);

  useEffect(() => {
    dispatch(getProductCategories());
  }, []);

  return (
    <Container>
      <Box>
        <BackTracker end={selectedCategory?.name ?? "Product Categories"} />
      </Box>
      <Box marginBottom="20px">
        <Grid
          container
          flexDirection="row-reverse"
          justifyContent={"center"}
          alignItems="center"
          sx={{
            backgroundColor: "#5c5c5c",
            padding: "40px 0",
          }}
        >
          <Grid item xs={8} md={6} lg={4} p={"20px"}>
            <img width={"100%"} src={Image03} alt="QH cover" />
          </Grid>
          <Grid item xs={8} md={6} lg={5} p={"20px"}>
            <Typography
              color="secondary"
              fontFamily="Comfortaa Variable"
              variant="h4"
              fontWeight={"bold"}
            >
              Quality Print Products
            </Typography>
            <Typography fontFamily="Poppins" color="secondary">
              Promote your business sustainably with our print options! Whatever
              impact you would like to make, we’ve got an option for every
              occasion and every business. We have the perfect size and paper
              stock to suit every occasion - no matter the budget you’re working
              with, so you’re sure to find what you’re looking for.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box maxWidth="lg">
        {isProductCategoryProcessing ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{ margin: "30px" }}
          >
            <CircularProgress sx={{ mb: "10px" }} />
            <Skeleton sx={{ width: "300px" }} animation="wave" />
            <Skeleton sx={{ width: "300px" }} animation="wave" />
            <Skeleton sx={{ width: "300px" }} animation="wave" />
            <Skeleton sx={{ width: "300px" }} animation="wave" />
            <Skeleton sx={{ width: "300px" }} animation="wave" />
          </Box>
        ) : (
          <>
            <Box position={"relative"}></Box>
          </>
        )}
      </Box>

      {!isProductCategoryProcessing && !selectedCategory && (
        <Typography>Product Category Not Found</Typography>
      )}

      {!isProductCategoryProcessing && selectedCategory && (
        <>
          <Box component="section" m={"20px 0"} mb={"40px"}>
            <Divider
              className="mt-4 mb-3"
              sx={{ fontWeight: "600", fontSize: "1.6rem" }}
            >
              Products
            </Divider>
            <Grid
              maxWidth="lg"
              width="100%"
              container
              columnSpacing={2}
              rowSpacing={2}
            >
              {selectedCategory?.products.map(
                ({ name, id, preview, details }, index) => (
                  <Grid item lg={4} md={5} sm={6} xs={12} key={index}>
                    <ProductCategoryBox
                      sx={{ width: "100%" }}
                      name={name}
                      link={`${selectedCategory.id}/${id}`}
                      details={details}
                      preview={preview}
                      product
                      design={true}
                    />
                  </Grid>
                )
              )}
            </Grid>
          </Box>

          {selectedCategory.productGroups?.map(
            ({ id: groupId, display, products }) => {
              return (
                <Box component="section" m={"20px 0"} mb={"40px"}>
                  <Divider
                    className="mt-4 mb-3"
                    sx={{ fontWeight: "600", fontSize: "1.6rem" }}
                  >
                    {display}
                  </Divider>
                  <Grid
                    maxWidth="lg"
                    width="100%"
                    container
                    columnSpacing={2}
                    rowSpacing={2}
                  >
                    {products?.map(({ name, id, preview, details }, index) => (
                      <Grid item lg={4} md={5} sm={6} xs={12} key={index}>
                        <ProductCategoryBox
                          sx={{ width: "100%" }}
                          name={name}
                          link={`${selectedCategory.id}/${groupId}/${id}`}
                          details={details}
                          preview={preview}
                          product
                          design={true}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              );
            }
          )}
        </>
      )}
    </Container>
  );
};

export default ProductCategory;
