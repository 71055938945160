import { Box, Container } from "@mui/material";
import BackTracker from "../components/BackTracker";

const AboutUs = () => {
  return (
    <Container>
      <Box>
        <BackTracker end={"About Us"} />
      </Box>
    </Container>
  );
};

export default AboutUs;
