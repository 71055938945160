import { useDispatch, useSelector } from "react-redux";
import Require from "../components/Required";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Input,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { createRef, useCallback, useEffect, useState } from "react";
import { logout } from "../slices/AuthReducer";
import BackTracker from "../components/BackTracker";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { updateProfile } from "../slices/ProfileReducer";

const Profile = () => {
  const dispatch = useDispatch();

  const form = createRef();

  const { isProcessing } = useSelector((state) => state.profile);
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const [isChanged, setIsChanged] = useState(false);
  const [name, setName] = useState(user?.unique_name);
  const [streetAddress, setStreetAddress] = useState(
    user?.[
      "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/streetaddress"
    ]
  );
  const [stateOrProvince, setStateOrProvince] = useState(
    user?.[
      "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/stateorprovince"
    ]
  );
  const [country, setCountry] = useState(
    user?.["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country"]
  );
  const [countryCode, setCountryCode] = useState(user?.country_code ?? "us");
  const [phoneNumber, setPhoneNumber] = useState(
    user?.["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/mobilephone"]
  );

  const [postalCode, setPostalCode] = useState(
    user?.["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/postalcode"]
  );
  const [gender, setGender] = useState(user?.gender ?? "OTHER");

  const onNameChanged = (e) => setName(e.currentTarget.value);
  const onStreetAddressChanged = (e) => setStreetAddress(e.currentTarget.value);
  const onStateOrProvinceChanged = (e) =>
    setStateOrProvince(e.currentTarget.value);
  const onCountryChanged = (e) => setCountry(e.currentTarget.value);
  const onCountryCodeChanged = (number, data, e) => {
    if (data.countryCode) {
      setCountryCode(data.countryCode);
    }
    onPhoneNumberChanged(number);
  };
  const onPhoneNumberChanged = (e) => setPhoneNumber(e);
  const onPostalCodeChanged = (e) => setPostalCode(e.currentTarget.value);
  const onGenderChanged = (e, value) => setGender(value);

  const onCancelChanges = () => {
    setName(user?.unique_name);
    setStreetAddress(
      user?.[
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/streetaddress"
      ]
    );
    setStateOrProvince(
      user?.[
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/stateorprovince"
      ]
    );
    setCountry(
      user?.["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country"]
    );
    setCountryCode(user?.country_code);
    setPhoneNumber(
      user?.[
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/mobilephone"
      ]
    );
    setPostalCode(
      user?.["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/postalcode"]
    );
    setGender(user?.gender);

    if (form.current) {
      form.current.querySelector(`[name="phoneNumber"]`).value = "";
      form?.current?.reset();
    }
  };

  useEffect(() => {
    setIsChanged(
      user?.unique_name !== name ||
        user?.[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/streetaddress"
        ] !== streetAddress ||
        user?.[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/stateorprovince"
        ] !== stateOrProvince ||
        user?.[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country"
        ] !== country ||
        user?.country_code !== countryCode ||
        user?.[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/mobilephone"
        ] !== phoneNumber ||
        user?.gender !== gender
    );
  }, [
    country,
    countryCode,
    name,
    phoneNumber,
    stateOrProvince,
    streetAddress,
    user,
    gender,
  ]);

  const onLogoutClick = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);
  console.log(user);
  const onUpdate = () => {
    let formData = new FormData();

    formData.append("name", name);
    formData.append("country", country);
    formData.append("mobileNumberCountryCode", countryCode);
    formData.append("mobileNumber", phoneNumber);
    formData.append("stateOrProvince", stateOrProvince);
    formData.append("streetAddress", streetAddress);
    formData.append("postalCode", postalCode);
    formData.append("gender", gender);

    dispatch(updateProfile(formData));
  };

  return (
    <Require authorized={isAuthenticated} redirect={"/login"}>
      <Container>
        <Box>
          <BackTracker end={"Profile"} />
        </Box>
        <Box display={"flex"} flexDirection={"row"}>
          <div
            style={{
              width: "100px",
              height: "100px",
              background: "#5B99C2",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "2rem",
              color: "white",
              letterSpacing: "2px",
              margin: "10px",
            }}
          >
            {user?.unique_name
              ?.split(" ")
              ?.map((word) => word.substr(0, 1))
              .join("")
              ?.toUpperCase()}
          </div>

          <Button
            sx={{ ml: "10px", color: "white", height: "fit-content" }}
            variant="contained"
            onClick={onLogoutClick}
          >
            Logout
          </Button>
        </Box>
        <Paper
          ref={form}
          component={"form"}
          sx={{ p: "25px", mt: "15px" }}
          variant="outlined"
          elevation={0}
        >
          <Box display={"flex"}>
            <Typography width={"60px"} sx={{ mr: "10px" }}>
              Name:
            </Typography>
            <TextField
              fullWidth
              disabled={isProcessing}
              size="small"
              variant="standard"
              defaultValue={user?.unique_name}
              spellCheck={false}
              onChange={onNameChanged}
            />
          </Box>
          <Box mt={"15px"} display={"flex"}>
            <Typography width={"60px"} sx={{ mr: "10px" }}>
              Email:{" "}
            </Typography>
            <TextField
              fullWidth
              size="small"
              variant="standard"
              disabled
              defaultValue={user?.email}
              spellCheck={false}
            />
          </Box>
          <Box display={"flex"} mt={"10px"}>
            <Typography sx={{ pt: "17px", mr: "10px" }}>
              Delivery Address:
            </Typography>
            <Box display={"flex"} flexDirection={"column"}>
              <TextField
                fullWidth
                disabled={isProcessing}
                size="small"
                variant="standard"
                label="Street Address"
                onChange={onStreetAddressChanged}
                defaultValue={
                  user?.[
                    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/streetaddress"
                  ]
                }
                spellCheck={false}
              />
              <TextField
                sx={{ mt: "5px" }}
                fullWidth
                disabled={isProcessing}
                size="small"
                variant="standard"
                label="State or Province"
                onChange={onStateOrProvinceChanged}
                defaultValue={
                  user?.[
                    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/stateorprovince"
                  ]
                }
                spellCheck={false}
              />
              <TextField
                sx={{ mt: "5px" }}
                fullWidth
                disabled={isProcessing}
                size="small"
                variant="standard"
                label="Country"
                onChange={onCountryChanged}
                defaultValue={
                  user?.[
                    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country"
                  ]
                }
                spellCheck={false}
              />
              <TextField
                sx={{ mt: "5px" }}
                fullWidth
                disabled={isProcessing}
                size="small"
                variant="standard"
                label="Postal Code"
                onChange={onPostalCodeChanged}
                defaultValue={
                  user?.[
                    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/postalcode"
                  ]
                }
                spellCheck={false}
              />
            </Box>
          </Box>
          <Box display={"flex"} mt={"20px"}>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                mr: "10px",
                width: "fit-content",
              }}
            >
              Phone Number:
            </Typography>
            <PhoneInput
              disabled={isProcessing}
              isValid={isChanged && !phoneNumber}
              containerStyle={{ width: "fit-content" }}
              country={user?.country_code ?? "us"}
              inputProps={{ name: "phoneNumber" }}
              value={
                user?.[
                  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/mobilephone"
                ] ?? ""
              }
              onChange={onCountryCodeChanged}
            />
          </Box>
          <Box display={"flex"} mt={"20px"}>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                mr: "10px",
                width: "fit-content",
              }}
            >
              Gender:
            </Typography>
            <FormControl>
              <RadioGroup
                onChange={onGenderChanged}
                value={gender ?? "OTHER"}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="MALE"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="FEMALE"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  value="OTHER"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          {isChanged && (
            <Box mt={"20px"}>
              <Button
                disabled={isProcessing}
                variant="contained"
                sx={{ color: "white", mr: "15px", ml: "auto" }}
                onClick={onUpdate}
              >
                Save
              </Button>
              <Button
                disabled={isProcessing}
                variant="contained"
                sx={{ color: "white" }}
                onClick={onCancelChanges}
              >
                Cancel
              </Button>
            </Box>
          )}
        </Paper>
      </Container>
    </Require>
  );
};

export default Profile;
