import { Breadcrumbs, Button, Link, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { router } from "../routes";

const BackTracker = ({ parent = "SayYes Print", paths, end }) => {
  const onNavigateClick = useCallback((e) => {
    router.navigate(e.currentTarget.getAttribute("to"));
  }, []);

  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ padding: "15px 0" }}>
      {end && (
        <Link
          component={Typography}
          to={"/"}
          fontFamily={"Poppins"}
          onClick={onNavigateClick}
          underline="hover"
          color="inherit"
          sx={{ cursor: "pointer" }}
        >
          {parent}
        </Link>
      )}

      {paths?.map(({ name, path }, index) => {
        return (
          name && (
            <Link
              key={index}
              fontFamily={"Poppins"}
              underline="hover"
              color="inherit"
              to={path}
              sx={{ cursor: "pointer" }}
              onClick={onNavigateClick}
            >
              {name}
            </Link>
          )
        );
      })}

      <Typography color="text.primary" fontFamily={"Poppins"}>
        {end ?? parent}
      </Typography>
    </Breadcrumbs>
  );
};

export default BackTracker;
