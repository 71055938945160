import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import { Provider } from "react-redux";
import store from "./store";
import Layout from "./components/Layout";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";

import "@fontsource/poppins";
import "@fontsource/ibm-plex-sans";
import "@fontsource-variable/comfortaa";
import "@fontsource-variable/cabin";
import "@fontsource/fira-sans";
import "@fontsource-variable/nunito-sans";
import "@fontsource-variable/nunito";
import "@fontsource-variable/montserrat";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/bs.css";

import "./styles/ITextField.css";

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Layout>
          <RouterProvider router={router} />
        </Layout>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
