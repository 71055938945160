import {
  Box,
  Button,
  CardContent,
  CardMedia,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Link,
  Pagination,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { createRef, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CloudUploadTwoTone,
  Delete,
  Download,
  Search,
} from "@mui/icons-material";
import { SERVER } from "../api/api_routes";
import { Card } from "reactstrap";
import DesignComponent from "../components/DesignComponent";
import Require from "../components/Required";
import BackTracker from "../components/BackTracker";
import { Transition, TransitionGroup } from "react-transition-group";
import GradientLinearProgress from "../components/mui/GradientLinearProgress";
import { uploadDesign } from "../slices/DesignReducer";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Designs = () => {
  const dispatch = useDispatch();

  const { designs, uploadProgress } = useSelector((state) => state.design);
  const { isAuthenticated, isProcessing } = useSelector((state) => state.auth);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [keyword, setKeyword] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  const uploadFormRef = createRef();

  const handleUploadDialogOpen = useCallback(() => {
    setUploadDialogOpen((state) => !state);
  }, []);

  const visibleDesigns = useMemo(() => {
    return (
      designs
        ?.filter(
          (design) =>
            !keyword ||
            design.name.toLowerCase().includes(keyword.toLowerCase())
        )
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) ?? []
    );
  }, [designs, keyword, page, rowsPerPage]);

  const handleChangePage = useCallback((e) => {
    setPage(parseInt(e.currentTarget.textContent));
  }, []);

  const handleOnKeywordChange = useCallback((e) => {
    setKeyword(e.currentTarget.value ?? null);
  }, []);

  const resetForm = useCallback(() => {
    document.querySelectorAll("form").forEach((e) => e.reset());

    document.querySelector('input[name="name"]').value = "";

    setFileName(null);
  }, []);

  const handleFileChange = useCallback((e) => {
    setFileName(e.target.files?.[0]?.name);
  }, []);

  const onUploadButtonClick = useCallback(
    (e) => {
      const name = uploadFormRef.current.querySelector('[name="name"]').value;
      const files = uploadFormRef.current.querySelector('[name="file"]').files;

      let formData = new FormData();
      formData.append("name", name);
      formData.append("file", files[0]);

      dispatch(uploadDesign(formData)).then(({ payload }) => {
        handleUploadDialogOpen();
        resetForm();
      });
    },
    [dispatch, handleUploadDialogOpen, resetForm, uploadFormRef]
  );

  useEffect(() => {
    setPage(0);
  }, [keyword]);

  return (
    !isProcessing && (
      <Container className="d-flex flex-column">
        <Box>
          <BackTracker end={"Designs"} />
        </Box>
        <Box
          className="d-flex"
          sx={{
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          {designs?.[0] && (
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Search sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                onChange={handleOnKeywordChange}
                id="input-search"
                label="Search"
                variant="standard"
              />
            </Box>
          )}
          <Button
            className="ms-2"
            variant="contained"
            sx={{ color: "white", height: "fit-content" }}
            onClick={handleUploadDialogOpen}
          >
            Upload
          </Button>
        </Box>
        {!designs?.[0] && (
          <Typography align="center">
            No uploaded designs found in your device.
          </Typography>
        )}
        <Grid
          component={TransitionGroup}
          justifyContent="center"
          container
          sx={{ flexGrow: 1 }}
        >
          {designs?.[0] && !visibleDesigns?.[0] && (
            <Typography>Nothing to show.</Typography>
          )}

          {visibleDesigns?.map(
            ({ id, name, source, previews, aspectRatio }) => {
              return (
                <Grid
                  sx={{ height: "100%" }}
                  component={Collapse}
                  key={id}
                  lg={3}
                  md={6}
                  xs={12}
                  item
                >
                  <DesignComponent
                    name={name}
                    id={id}
                    source={source}
                    previews={previews}
                    aspectRatio={aspectRatio}
                  />
                </Grid>
              );
            }
          )}
        </Grid>
        {designs?.[0] && (
          <Box>
            <Pagination
              onChange={handleChangePage}
              count={Math.ceil((designs?.length ?? 0) / rowsPerPage)}
              color="primary"
            />
          </Box>
        )}
        <Dialog
          open={uploadDialogOpen}
          keepMounted
          onClose={handleUploadDialogOpen}
          aria-describedby="alert-dialog-slide-description"
          sx={{ zIndex: "1000" }}
        >
          <DialogTitle>Upload a custom design</DialogTitle>
          <DialogContent>
            <FormControl ref={uploadFormRef} component="form">
              <Grid container m="15px 0" justifyContent="center">
                <Grid item xs={10} md={10} lg={10} p={"10px"}>
                  <TextField
                    sx={{ width: "100%" }}
                    label="Design Name"
                    variant="outlined"
                    name="name"
                    helperText={fileName}
                  />
                </Grid>
                <Grid item xs={5} md={4} lg={10} p={"10px"} pt="0">
                  <Button
                    disabled={!!uploadProgress}
                    component="label"
                    type="button"
                    role={undefined}
                    variant="outlined"
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                    }}
                    tabIndex={-1}
                  >
                    Select file
                    <VisuallyHiddenInput
                      onChange={handleFileChange}
                      disabled={!!uploadProgress}
                      type="file"
                      name="file"
                      accept=".pdf, .jpeg, .jpg, .png"
                    />
                  </Button>
                </Grid>
              </Grid>
              {uploadProgress && (
                <Box>
                  <GradientLinearProgress
                    variant="determinate"
                    value={uploadProgress * 100}
                  />
                </Box>
              )}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={!!uploadProgress}
              onClick={handleUploadDialogOpen}
              variant="contained"
              sx={{ fontFamily: "Poppins", color: "white" }}
            >
              Close
            </Button>
            <Button
              disabled={!!uploadProgress || !fileName}
              onClick={onUploadButtonClick}
              variant="contained"
              sx={{ fontFamily: "Poppins", color: "white" }}
              startIcon={<CloudUploadTwoTone />}
            >
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    )
  );
};

export default Designs;
