import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { clearNotifications } from "../slices/ProcessReducer";
import ProcessingIndicator from "./ProcessingIndicator";

const Main = ({ children, sx }) => {
  const { notifications } = useSelector((state) => state.process);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    Object.values(notifications).forEach(({ message, type }) =>
      enqueueSnackbar(message, { variant: type })
    );
    clearNotifications();
  }, [enqueueSnackbar, notifications]);

  return (
    <>
      <ProcessingIndicator />
      <main style={{ ...sx }}>{children}</main>
    </>
  );
};

export default Main;
