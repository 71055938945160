import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { getProductCategories } from "../slices/ProductReducer";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../images/Sayyes Digital Printing Logo 1x2.gif";

const Footer = forwardRef(({}, ref) => {
  const dispatch = useDispatch();

  const { categories, isProductCategoryProcessing } = useSelector(
    (state) => state.product
  );

  useEffect(() => {
    dispatch(getProductCategories());
  }, []);

  return (
    <footer
      className="mt-4"
      ref={ref}
      style={{
        background: "linear-gradient(to top right, #010101, #131313)",
      }}
    >
      <Container className="pb-4">
        <Grid container>
          <Grid item xs={12} md={6} lg={3}>
            <Typography
              color="secondary"
              sx={{ opacity: 0.7, color: "white", m: "15px 10px" }}
            >
              Talk to Us
            </Typography>
            <Box display="flex" flexDirection="column">
              <Typography
                color="secondary"
                sx={{ color: "white", opacity: "0.8", my: "6px" }}
              >
                Monday - Friday: 9am - 6:00pm
              </Typography>
              <Typography
                fontSize={"14px !important"}
                color="secondary"
                sx={{ color: "white", my: "3px" }}
              >
                8216 Tamarron Dr, Plainsboro, NJ 08536
              </Typography>
              <Link
                color="secondary"
                fontSize={"14px !important"}
                sx={{ color: "white", my: "3px" }}
                href={"tel:16105975438"}
              >
                +1 610 597 5438
              </Link>
              <Link
                color="secondary"
                fontSize={"14px !important"}
                sx={{ color: "white", my: "3px" }}
                href={"mailto:info@syayesprint.com"}
              >
                info@sayyesprint.com
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Typography
              color="secondary"
              sx={{ opacity: 0.7, color: "white", m: "15px 10px" }}
            >
              Services
            </Typography>
            <Box display="flex" flexDirection="column">
              {isProductCategoryProcessing ? (
                <CircularProgress />
              ) : (
                <>
                  {categories?.[0] && (
                    <Link
                      fontSize={"14px !important"}
                      color="secondary"
                      sx={{ color: "white" }}
                      href={`/productcategory/${categories[0].id}`}
                    >
                      {categories[0].name}
                    </Link>
                  )}
                  {categories?.[1] && (
                    <Link
                      fontSize={"14px !important"}
                      color="secondary"
                      sx={{ color: "white" }}
                      href={`/productcategory/${categories[1].id}`}
                    >
                      {categories[1].name}
                    </Link>
                  )}
                  {categories?.[2] && (
                    <Link
                      fontSize={"14px !important"}
                      color="secondary"
                      sx={{ color: "white" }}
                      href={`/productcategory/${categories[2].id}`}
                    >
                      {categories[2].name}
                    </Link>
                  )}
                  {categories?.[3] && (
                    <Link
                      fontSize={"14px !important"}
                      color="secondary"
                      sx={{ color: "white" }}
                      href={`/productcategory/${categories[3].id}`}
                    >
                      {categories[3].name}
                    </Link>
                  )}
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Typography
              color="secondary"
              sx={{ opacity: 0.7, color: "white", m: "15px 10px" }}
            >
              Here to Help
            </Typography>
            <Box display="flex" flexDirection="column">
              <Link
                fontSize={"14px !important"}
                sx={{ color: "white" }}
                color="secondary"
                href={"/support"}
              >
                Support
              </Link>
              {/*<Link sx={{ color: "white" }} color="secondary" href={"/aboutus"}>
                About Us
              </Link> */}
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Typography
              color="secondary"
              sx={{ opacity: 0.7, color: "white", m: "15px 10px" }}
            >
              Company
            </Typography>
            <Box display="flex" flexDirection="column">
              <img alt="company" src={Logo} width={120} height={60} />{" "}
              <Typography
                fontSize={"14px !important"}
                color="secondary"
                sx={{ color: "white" }}
              >
                Sayyes Print
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
});

export default Footer;
