import { Circle, Delete, Download } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Link,
  List,
  ListItem,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteOrder } from "../slices/OrderReducer";
import { SERVER, STATIC_URI } from "../api/api_routes";

const OrderRow = ({ order }) => {
  const { categories } = useSelector((state) => state.product);
  const { user } = useSelector((state) => state.auth);

  const status = useMemo(() => {
    switch (order.status) {
      case 0:
        return ["Pending", "#FEFF9F"];
      case 1:
        return ["Cancelled", "red"];
      case 2:
        return ["Completed", "#72BF78"];
      default:
        return ["Pending", "yellow"];
    }
  }, [order]);

  const items = useMemo(() => {
    let obj = {};
    categories.forEach((category) => {
      category.productGroups.forEach((group) => {
        group.products.forEach((product) => {
          order.purchasedProducts.forEach(({ productItemId, id }) => {
            product.productItems.forEach((item) => {
              if (item.id === productItemId) {
                obj[id] = {
                  name: product.name,
                  options: item.productOptions
                    .map((option) => option.productOptionItem.name)
                    .join(", "),
                };
              }
            });
          });
        });
      });

      category.products.forEach((product) => {
        order.purchasedProducts.forEach(({ productItemId, id }) => {
          product.productItems.forEach((item) => {
            if (item.id === productItemId) {
              obj[id] = {
                name: product.name,
                options: item.productOptions
                  .map((option) => option.productOptionItem.name)
                  .join(", "),
              };
            }
          });
        });
      });
    });

    return obj;
  }, [categories, order?.purchasedProducts]);

  const payNow = () => {
    if (order.id && user?.email) {
      let searchParams = new URLSearchParams();
      searchParams.set("orderId", order.id);
      searchParams.set("email", user?.email);

      window.location = `${SERVER}/checkout?${searchParams.toString()}`;
    }
  };

  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell sx={{ maxWidth: "500px" }} align="center">
        <List>
          {order &&
            order?.purchasedProducts?.map(
              ({ productItem, id, quantity, design }, index) => {
                return (
                  <ListItem
                    key={index}
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>
                      <Typography>{items[id]?.name}</Typography>
                      <Typography variant="body2">
                        {items[id]?.options}
                      </Typography>
                    </Typography>
                    <Box display={"flex"} alignItems={"center"}>
                      <Typography m={"0 10px"}>x{quantity}</Typography>
                      {design?.path && (
                        <Tooltip title="Download Design">
                          <IconButton
                            m={"0 10px"}
                            component={Link}
                            href={`${STATIC_URI}${design.path}`}
                          >
                            <Download />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </ListItem>
                );
              }
            )}
        </List>
      </TableCell>
      <TableCell align="center">
        <Typography fontFamily={"Poppins"} variant="body2">
          ${order.price}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Chip
          label={status[0]}
          variant="outlined"
          sx={{
            background: status[1],
            color: "black",
            border: "none",
            fontFamily: "Poppins",
          }}
        />
      </TableCell>
      <TableCell align="center">
        {order.status === 0 && (
          <Button
            onClick={payNow}
            size="small"
            variant="contained"
            sx={{ color: "white" }}
          >
            Pay
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default OrderRow;
