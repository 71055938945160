import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { router } from "../routes";

const ProductBox = ({ isOpen, handleClose, ...props }) => {
  const { categories, isProcessing } = useSelector((state) => state.product);

  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleOnClick = useCallback(
    (e) => {
      const target = e.currentTarget.getAttribute("to");
      setSelectedCategory(
        target && target != ""
          ? categories.find((category) => category.id == target)
          : null
      );
    },
    [categories]
  );

  const handleNavigateProductCategory = useCallback(
    (e) => {
      const id = e.currentTarget?.getAttribute("to");
      router.navigate(`/productcategory/${id}`);
      if (handleClose) handleClose();
    },
    [handleClose]
  );

  const handleNavigateProduct = useCallback(
    (e) => {
      const id = e.currentTarget?.getAttribute("to");
      router.navigate(`/product/${id}`);
      if (handleClose) handleClose();
    },
    [handleClose]
  );

  const handleNavigateProductGroup = useCallback(
    (e) => {
      const id = e.currentTarget?.getAttribute("to");
      const categoryId = e.currentTarget?.getAttribute("category");
      router.navigate(`/productgroup/${categoryId}/${id}`);
      if (handleClose) handleClose();
    },
    [handleClose]
  );

  useEffect(() => {
    setSelectedCategory(null);
  }, [isOpen]);

  return (
    <Container
      sx={{
        position: "absolute",
        top: "100%",
        display: isOpen ? "flex" : "none",
        backgroundColor: "white",
      }}
      {...props}
    >
      {isProcessing ? (
        <Box className="d-flex align-items-center justify-content-center m-4">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {!selectedCategory ? (
            <Grid container>
              {categories.map(({ name, id, previewSmall }, index) => {
                return (
                  <Grid item key={index}>
                    <Box
                      className="m-3 p-2"
                      onClick={handleOnClick}
                      to={id}
                      sx={{
                        cursor: "pointer",
                        transition: "0.3s ease border-color",
                        border: "3px solid #eee",
                        ":hover": {
                          borderColor: "var(--main-color)",
                        },
                      }}
                    >
                      <img
                        src={previewSmall}
                        alt="Preview"
                        width={150}
                        height={150}
                      />
                      <Typography textAlign={"center"}>{name}</Typography>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <Box className="d-flex">
              <Box className="d-flex justify-content-center align-items-center flex-column">
                <Button variant="text" onClick={handleOnClick}>
                  <ArrowBack /> Back to all products
                </Button>
                <Box>
                  <Box className="m-4">
                    <img
                      src={selectedCategory.previewSmall}
                      alt="Preview"
                      width={150}
                      height={150}
                    />
                    <Typography fontFamily={"Poppins"} textAlign={"center"}>
                      {selectedCategory.name}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Box>
                <List>
                  <ListItem className="mt-1 pb-0 pt-1">
                    <Button
                      className="mt-1 pb-0 pt-1"
                      to={selectedCategory.id}
                      onClick={handleNavigateProductCategory}
                      sx={{ fontFamily: "Poppins", fontWeight: 600 }}
                    >
                      Shop all {selectedCategory.name}
                    </Button>
                  </ListItem>
                  {selectedCategory.products?.map(({ name, id }) => {
                    return (
                      <ListItem className="mt-1 pb-0 pt-1">
                        <Button
                          className="mt-1 pb-0 pt-1"
                          to={`${selectedCategory.id}/${id}`}
                          onClick={handleNavigateProduct}
                          sx={{ fontFamily: "Poppins", fontWeight: 600 }}
                        >
                          {name}
                        </Button>
                      </ListItem>
                    );
                  })}
                  {selectedCategory.productGroups?.map(({ name, id }) => {
                    return (
                      <ListItem className="mt-1 pb-0 pt-1">
                        <Button
                          className="mt-1 pb-0 pt-1"
                          to={id}
                          category={selectedCategory.id}
                          onClick={handleNavigateProductGroup}
                          sx={{ fontFamily: "Poppins", fontWeight: 600 }}
                        >
                          {name}
                        </Button>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </Box>
          )}
        </>
      )}
    </Container>
  );
};

export default ProductBox;
