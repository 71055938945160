import {
  AutofpsSelectRounded,
  CheckCircle,
  CheckCircleOutline,
  CloudUpload,
  CloudUploadTwoTone,
  Create,
  Done,
  PanTool,
  PanToolAlt,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { createRef, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Transition } from "react-transition-group";
import { uploadDesign } from "../slices/DesignReducer";
import GradientLinearProgress from "./mui/GradientLinearProgress";
import { updateItemInCart } from "../slices/CartReducer";
import { SERVER, STATIC_URI } from "../api/api_routes";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AddToCartComplete = ({
  isOpen,
  handleClose,
  productName,
  openUploadDesign,
  selectDesignDialogOpen,
  selectedDesign,
  aspect,
  ...rest
}) => {
  const dispatch = useDispatch();

  const { cart } = useSelector((state) => state.cart);
  const { designs } = useSelector((state) => state.design);

  const [showPreview, SetShowPreview] = useState(false);

  const onShowPreviewClick = useCallback(() => {
    SetShowPreview((state) => !state);
  }, []);

  const currentItem = useMemo(() => {
    if (cart && cart.length === 0) return;
    if (cart.length === 1) return cart[0];

    return cart.reduce((a, b) => (a.itemId > b.itemId ? a : b));
  }, [cart]);

  const options = useMemo(
    () =>
      currentItem?.item?.productOptions
        ?.map(({ productOptionItem }) => productOptionItem?.name)
        .join(", "),
    [currentItem]
  );

  useEffect(() => {
    if (isOpen && currentItem) {
      let item = currentItem;
      if (selectedDesign) {
        item = {
          design: {
            name: selectedDesign.name,
            path: selectedDesign.source,
            preview: selectedDesign.previews?.[0] ?? selectedDesign.source,
          },
          ...currentItem,
        };
      } else {
        const { design, ...rest } = currentItem;
        if (design) {
          item = rest;
        }
      }
      dispatch(updateItemInCart({ id: item.itemId, cartItem: item }));
    }
  }, [selectedDesign]);

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} {...rest}>
        <DialogTitle>
          <CheckCircleOutline color="success" /> Item added to Cart
        </DialogTitle>
        <DialogContent className="px-3 py-1">
          <DialogContentText className="mt-1" variant="h5">
            {productName}
          </DialogContentText>
          <DialogContentText>{options}</DialogContentText>
          {selectedDesign && (
            <Box>
              <Typography>
                Design -{" "}
                <span style={{ fontWeight: "bold" }}>
                  {selectedDesign.name}
                </span>
                <IconButton onClick={onShowPreviewClick}>
                  {showPreview ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </Typography>
            </Box>
          )}
          {showPreview && selectedDesign && (
            <Box sx={{ maxWidth: "400px" }}>
              <img
                style={{ width: "100%", height: "100%" }}
                alt="design preview"
                src={
                  selectedDesign.previews?.[0] &&
                  `${STATIC_URI}${selectedDesign.previews?.[0]}`
                }
              />
            </Box>
          )}
          <Box className="d-flex flex-wrap justify-content-center align-items-center">
            <Button onClick={openUploadDesign}>
              <CloudUpload className="mx-1" />
              Upload Design
            </Button>

            <Button
              disabled={
                !designs?.[0] ||
                !(
                  aspect &&
                  designs?.find(({ aspectRatio }) => aspectRatio === aspect)
                )
              }
              onClick={selectDesignDialogOpen}
            >
              <PanToolAlt className="mx-1" />
              Select Design
            </Button>

            {/* <Button onClick={handleClose}>
              <Create className="mx-1" /> Design Online
            </Button> */}
          </Box>

          <DialogContentText variant="body2">
            You can supply your design in your basket when you're ready
          </DialogContentText>
          <Divider className="mt-2" />
        </DialogContent>
        <DialogActions className="mt-1">
          <Box className="mt-2">
            <Button onClick={handleClose}>Continue Shopping</Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddToCartComplete;
