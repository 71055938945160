import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { router } from "../routes";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { userRegister } from "../slices/AuthReducer";
import Require from "../components/Required";
import Copyright from "../components/Copyright";

const Register = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const [firstNameFilled, setFirstNameFilled] = React.useState([false, true]);
  const [lastNameFilled, setLastNameFilled] = React.useState([false, true]);
  const [emailFilled, setEmailFilled] = React.useState([false, true]);
  const [passwordFilled, setPasswordFilled] = React.useState([false, true]);
  const [confirmPasswordFilled, setConfirmPasswordFilled] = React.useState([
    false,
    true,
  ]);
  const [agreedToTerms, setAgreedToTerms] = React.useState(false);

  const passwordVisibilityChanged = React.useCallback(() => {
    setShowPassword((state) => !state);
  }, []);

  const onFocusField = React.useCallback(
    (e) => {
      const { name } = e.target;
      switch (name) {
        case "firstName":
          setFirstNameFilled([firstNameFilled[0], false]);
          break;
        case "lastName":
          setLastNameFilled([lastNameFilled[0], false]);
          break;
        case "email":
          setEmailFilled([emailFilled[0], false]);
          break;
        case "password":
          setPasswordFilled([passwordFilled[0], false]);
          break;
        case "confirmPassword":
          setConfirmPasswordFilled([confirmPasswordFilled[0], false]);
          break;
        default:
      }
    },
    [
      confirmPasswordFilled,
      emailFilled,
      firstNameFilled,
      lastNameFilled,
      passwordFilled,
    ]
  );

  const onChangeField = React.useCallback(
    (e) => {
      const { name, value } = e.target;
      switch (name) {
        case "firstName":
          setFirstNameFilled([value !== "", firstNameFilled[1]]);
          break;
        case "lastName":
          setLastNameFilled([value !== "", lastNameFilled[1]]);
          break;
        case "email":
          setEmailFilled([value.match(/^.{3,}@\w+\.+\w+$/), emailFilled[1]]);
          break;
        case "password":
          setPasswordFilled([value.match(/.{8,}/), passwordFilled[1]]);
          break;
        case "confirmPassword":
          setConfirmPasswordFilled([
            value.match(/.{8,}/),
            confirmPasswordFilled[1],
          ]);
          break;
        case "terms":
          setAgreedToTerms(e.target.checked);
          break;
        default:
      }
    },
    [
      confirmPasswordFilled,
      emailFilled,
      firstNameFilled,
      lastNameFilled,
      passwordFilled,
    ]
  );

  const confirmPasswordVisibilityChanged = React.useCallback(() => {
    setShowConfirmPassword((state) => !state);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    dispatch(userRegister(data));
  };

  const navigateToLogin = React.useCallback(() => {
    router.navigate("/login");
  }, []);

  return (
    <Require authorized={!isAuthenticated} redirect={"/home"}>
      <Container component="main" maxWidth="md" sx={{ fontFamily: "Poppins" }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{
              m: 1,
              bgcolor: "secondary.main",
            }}
          >
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  error={!firstNameFilled[1] && !firstNameFilled[0]}
                  autoFocus
                  onFocus={onFocusField}
                  onChange={onChangeField}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  error={!lastNameFilled[1] && !lastNameFilled[0]}
                  onFocus={onFocusField}
                  onChange={onChangeField}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  error={!emailFilled[1] && !emailFilled[0]}
                  onFocus={onFocusField}
                  onChange={onChangeField}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    htmlFor="outlined-password"
                    error={!passwordFilled[1] && !passwordFilled[0]}
                  >
                    Password
                  </InputLabel>
                  <OutlinedInput
                    required
                    label="Password"
                    fullWidth
                    id="outlined-password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    error={!passwordFilled[1] && !passwordFilled[0]}
                    onFocus={onFocusField}
                    onChange={onChangeField}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={passwordVisibilityChanged}
                          onMouseDown={passwordVisibilityChanged}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    htmlFor="outlined-confirm-password"
                    error={
                      !confirmPasswordFilled[1] && !confirmPasswordFilled[0]
                    }
                  >
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    required
                    fullWidth
                    label="Confirm Password"
                    id="outlined-confirm-password"
                    name="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    onFocus={onFocusField}
                    onChange={onChangeField}
                    error={
                      !confirmPasswordFilled[1] && !confirmPasswordFilled[0]
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirm password visibility"
                          onClick={confirmPasswordVisibilityChanged}
                          onMouseDown={confirmPasswordVisibilityChanged}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="agreeToTerms"
                      color="primary"
                      required
                      name="terms"
                      onChange={onChangeField}
                    />
                  }
                  label="I agree to all the terms and conditions."
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, color: "white" }}
              disabled={
                !firstNameFilled[0] ||
                !lastNameFilled[0] ||
                !emailFilled[0] ||
                !passwordFilled[0] ||
                !confirmPasswordFilled[0] ||
                !agreedToTerms
              }
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link
                  component="button"
                  onClick={navigateToLogin}
                  href="#"
                  variant="body2"
                >
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </Require>
  );
};

export default Register;
