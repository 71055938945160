import { FormControl, Input, TextField } from "@mui/material";

const ITextField = ({ inputProps, className, inputSx, sx, ...props }) => {
  return (
    <FormControl className={className} variant="standard" sx={{ ...sx }}>
      <Input
        className="itextfield"
        placeholder="Search for a product"
        id="input-with-icon-adornment"
        sx={{
          transition: "0.3s ease",
          border: "1px solid #ced4da !important",
          ":before": { borderBottom: "none !important" },
          ":hover": { borderBottom: "none" },
          ":after": {
            borderBottom: "none",
          },
          ":hover .itextfield:before": {
            borderBottom: "1px solid #ced4da !important",
          },
        }}
        {...inputProps}
        inputProps={{
          sx: {
            fontFamily: "Poppins",
            transition: "0.3s ease",

            ...inputSx,
          },
        }}
      />
    </FormControl>
  );
};

export default ITextField;
