const SERVER = process.env.REACT_APP_API ?? "https://localhost:7143";
const API = SERVER + "/api/v1";
const STATIC_URI = `${SERVER}/static_data`;

const AUTH_URI = "/auth";
const PRODUCT_CATEGORY_URI = "/productcategory";
const DESIGN_URI = "/design";
const CART_URI = "/cart";
const ORDER_URI = "/order";
const PROFILE_URI = "/profile";
const SUPPORT_URI = "/support";

const constructUrl = (uri, endpoint) => {
  return API + uri + (endpoint ?? "");
};

const AUTH = {
  GET_PUBLIC_KEY: {
    method: "get",
    url: constructUrl(AUTH_URI, "/getpublickey"),
  },
  LOGIN: {
    method: "post",
    url: constructUrl(AUTH_URI, "/login"),
  },
  REGISTER: {
    method: "put",
    url: constructUrl(AUTH_URI, "/register"),
  },
  GOOGLE_OAUTH: {
    method: "post",
    url: `${SERVER}/signin-google`,
  },
};

const PRODUCT_CATEGORY = {
  GET_PRODCUT_CATEGORIES: {
    method: "get",
    url: constructUrl(PRODUCT_CATEGORY_URI),
  },
};

const DESIGN = {
  UPLOAD: {
    method: "post",
    url: constructUrl(DESIGN_URI, "/upload"),
  },
};

const CART = {
  GET: {
    method: "get",
    url: constructUrl(CART_URI),
  },
  ADD: {
    method: "post",
    url: constructUrl(CART_URI),
  },
  DELETE: {
    method: "delete",
    url: constructUrl(CART_URI),
  },
};

const ORDER = {
  GET: {
    method: "get",
    url: constructUrl(ORDER_URI),
  },
  ADD: {
    method: "post",
    url: constructUrl(ORDER_URI),
  },
  DELETE: {
    method: "delete",
    url: constructUrl(ORDER_URI),
  },
};

const PROFILE = {
  UPDATE: {
    method: "post",
    url: constructUrl(PROFILE_URI, "/update"),
  },
};

const SUPPORT = {
  MESSAGE: {
    method: "post",
    url: constructUrl(SUPPORT_URI),
  },
};

export {
  AUTH,
  PRODUCT_CATEGORY,
  DESIGN,
  SERVER,
  CART,
  ORDER,
  STATIC_URI,
  PROFILE,
  SUPPORT,
};
