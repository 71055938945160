import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isProcessing: false,
  notifications: {},
};

const slice = createSlice({
  name: "ProcessSlice",
  initialState: initialState,
  reducers: {
    setProcessProcessing: (state, { payload }) => {
      state.isProcessing = payload;
    },
    addNotification: (state, { payload }) => {
      let id = Math.random();
      state.notifications = {
        [id]: { ...payload },
        ...state.notifications,
      };
    },
    clearNotifications: (state) => {
      Object.keys(state.notifications).forEach(
        (key) => delete state.notifications[key]
      );
      state.notifications = {};
    },
  },
});

export const { setProcessProcessing, addNotification, clearNotifications } =
  slice.actions;

export default slice.reducer;
