import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { SERVER, STATIC_URI } from "../api/api_routes";
import { useDispatch } from "react-redux";
import { useCallback, useMemo, useState } from "react";
import { Delete, Tty, Visibility, VisibilityOff } from "@mui/icons-material";
import { deleteItemFromCart } from "../slices/CartReducer";
import { DESIGN_PRICE } from "../util/constants";

const DrawerCartItem = ({ id, productItem, quantity, design, disabled }) => {
  const dispatch = useDispatch();

  const [isProcessing, setIsProcessing] = useState(false);
  const [showDesign, setShowDesign] = useState(false);

  const options = useMemo(
    () =>
      productItem?.productOptions
        ?.map(({ productOptionItem }) => productOptionItem?.name)
        .join(", "),
    [productItem?.productOptions]
  );

  const handleOnDesignShowStateChange = useCallback(() => {
    setShowDesign((state) => !state);
  }, []);

  const onDeleteClick = useCallback(() => {
    dispatch(deleteItemFromCart(id));
    setIsProcessing(false);
  }, [dispatch, id]);

  return (
    <Card
      variant="outlined"
      elevation={0}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderColor: "var(--alt-color)",
        borderBottom: "1px solid",
        border: "none",
      }}
    >
      {design?.preview && (
        <>
          {showDesign && (
            <CardMedia
              component="img"
              alt="Design preview"
              src={`${STATIC_URI}${design?.preview}`}
              sx={{
                height: { lg: "300px", md: "250px", sm: "200px", xs: "150px" },
                width: "fit-content",
              }}
            />
          )}
        </>
      )}
      <CardContent sx={{ width: "100%" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography fontFamily="Poppins">
            <Typography fontSize={"16px"}>
              {productItem?.product?.name} {productItem?.name}
            </Typography>
            <Typography fontSize={"12px"}>{options}</Typography>
          </Typography>
          <Typography fontSize={"14px"} fontFamily="Poppins">
            ${productItem?.basePrice}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography fontSize={"14px"} fontFamily="Poppins">
            Quantity
          </Typography>
          <Typography fontSize={"14px"} fontFamily="Poppins">
            x{quantity}
          </Typography>
        </Box>
        {design && (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography fontSize={"14px"} fontFamily="Poppins">
              Design Price
            </Typography>
            <Typography fontSize={"14px"} fontFamily="Poppins">
              ${10.0}
            </Typography>
          </Box>
        )}
        <Divider />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography fontSize={"18px"} fontFamily="Poppins">
            Total
          </Typography>
          <Typography fontSize={"18px"} fontFamily="Poppins">
            $
            {(
              productItem?.basePrice * quantity +
              (design ? DESIGN_PRICE : 0)
            ).toFixed(2)}
          </Typography>
        </Box>
      </CardContent>
      <CardActions sx={{ width: "100%" }}>
        {design && (
          <Button
            onClick={handleOnDesignShowStateChange}
            startIcon={showDesign ? <VisibilityOff /> : <Visibility />}
            disabled={isProcessing || disabled}
            variant="contained"
            sx={{ fontFamily: "Poppins", color: "White" }}
            size="medium"
          >
            {showDesign ? "Hide" : "Show"} Design
          </Button>
        )}
        <Button
          disabled={isProcessing || disabled}
          variant="contained"
          sx={{ fontFamily: "Poppins", color: "White" }}
          size="medium"
          onClick={onDeleteClick}
          startIcon={<Delete />}
        >
          Delete
        </Button>
      </CardActions>
    </Card>
  );
};

export default DrawerCartItem;
