import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Icon,
  Link,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import "../styles/ProductCategoryBox.css";
import { useCallback, useEffect } from "react";
import { router } from "../routes";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Circle } from "@mui/icons-material";

const ProductCategoryBox = ({
  name,
  description,
  link,
  preview = "https://blackscreen.space/images/pro/black-screen_39.png",
  product = false,
  details,
  design,
}) => {
  const onClick = useCallback(() => {
    router.navigate(`/${product ? "product" : "productcategory"}/${link}`);
  }, [link, product]);

  return (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <CardMedia component="img" height={"200px"} alt="Cover" src={preview} />
      <CardContent
        sx={{
          backgroundColor: "#f5f5ff",
          flexGrow: 1,
        }}
      >
        <Typography
          textAlign="start"
          gutterBottom
          variant="h5"
          component="div"
          fontFamily={"Poppins"}
          fontWeight={600}
        >
          {name}
        </Typography>
        {description && (
          <Typography
            textAlign="start"
            variant="body2"
            color="text.secondary"
            fontFamily={"Poppins"}
          >
            {description}
          </Typography>
        )}
        {details && (
          <List>
            {details.map((detail, index) => (
              <ListItem key={index}>
                <ListItemIcon sx={{ minWidth: "40px" }}>
                  <Circle fontSize="15px" />
                </ListItemIcon>
                <Typography variant="body2" fontFamily={"Poppins"}>
                  {detail}
                </Typography>
              </ListItem>
            ))}
          </List>
        )}
      </CardContent>
      <CardActions
        className="d-flex flex-column"
        sx={{ backgroundColor: "#f5f5ff" }}
      >
        <Button
          variant="contained"
          className="mx-0"
          sx={{
            color: "white",
            fontWeight: 600,
            fontFamily: "Poppins",
            width: "100%",
          }}
          size="medium"
          onClick={onClick}
        >
          {product ? "View Products" : "Shop now"}
        </Button>
        {/*design && (
          <Button
            className="mx-0 mt-2"
            variant="contained"
            sx={{
              color: "white",
              fontWeight: 600,
              fontFamily: "Poppins",
              width: "100%",
              backgroundColor: "",
            }}
            size="medium"
          >
            Design Online
          </Button>
        )*/}
      </CardActions>
    </Card>
  );
};

export default ProductCategoryBox;
