import { createBrowserRouter } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Home from "./pages/Home";
import Products from "./pages/ProductCategory";
import ProductCategory from "./pages/ProductCategory";
import Product from "./pages/Product";
import Support from "./pages/Support";
import Orders from "./pages/Orders";
import Designs from "./pages/Designs";
import AboutUs from "./pages/AboutUs";
import Profile from "./pages/Profile";
import ProductGroup from "./pages/ProductGroup";

const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/productcategory/:id",
    element: <ProductCategory />,
  },
  {
    path: "/productgroup/:categoryId/:groupId",
    element: <ProductGroup />,
  },
  {
    path: "#",
    element: <ProductCategory />,
    name: "Shop All Products",
    nav: true,
    shopAll: true,
  },
  {
    path: "/product/:categoryId/:id",
    element: <Product />,
  },
  {
    path: "/product/:categoryId/:groupId/:id",
    element: <Product />,
  },
  {
    path: "/orders",
    element: <Orders />,
    name: "Orders",
    nav: true,
    auth: true,
  },
  {
    path: "/designs",
    element: <Designs />,
    name: "Designs",
    nav: true,
  },
  {
    path: "/support",
    element: <Support />,
    name: "Support",
    nav: true,
  },
  {
    path: "/aboutus",
    element: <AboutUs />,
  },
];

const router = createBrowserRouter(routes);

export { router, routes };
