import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Add,
  CheckCircle,
  Circle,
  CloudUpload,
  CloudUploadTwoTone,
  Done,
  LaptopMac,
  Pin,
  PlusOne,
  Print,
  RadioButtonChecked,
  RadioButtonUnchecked,
  Remove,
  RingVolume,
} from "@mui/icons-material";
import { Unstable_NumberInput as NumberInput } from "@mui/base";
import { getProductCategories } from "../slices/ProductReducer";
import { uploadDesign } from "../slices/DesignReducer";
import Designs from "../components/Designs";
import Require from "../components/Required";
import { addItemToCart } from "../slices/CartReducer";
import BackTracker from "../components/BackTracker";
import { STATIC_URI } from "../api/api_routes";
import GradientLinearProgress from "../components/mui/GradientLinearProgress";
import { DESIGN_PRICE } from "../util/constants";
import PDF_LOGO from "../images/PDF-PDF-Icon.png";
import PS_LOGO from "../images/PS-Photoshop.png";
import AI_LOGO from "../images/AI-Illustrator.png";
import ID_LOGO from "../images/ID-InDesign.png";
import { isElementInViewport } from "../util/util-functions";
import ProductContent from "../components/ProductContent";
import AddToCartComplete from "../components/AddToCartComplete";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Product = () => {
  const dispatch = useDispatch();

  const {
    product: { categories },
    auth: { isAuthenticated },
    design: { uploadProgress, designs },
  } = useSelector((state) => state);
  const { categoryId, groupId, id } = useParams();

  const quantityRef = createRef();
  const uploadFormRef = createRef();

  const [product, setProduct] = useState(null);
  const [category, setCategory] = useState(null);
  const [group, setGroup] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [selectedDesign, setSelectedDesign] = useState(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [designSelectDialogOpen, setDesignSelectDialogOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [commonOptions, setCommonOptions] = useState(null);
  const [options, setOptions] = useState(null);
  const [selectedValues, setSelectedValues] = useState({});
  const [customQuantity, setCustomQuantity] = useState(null);
  const [scrolledToPriceTable, setScrolledToPriceTable] = useState(false);
  const [itemAddedToCart, setItemAddedToCart] = useState(false);

  const rows = useMemo(() => new Array(5).fill(1), []);

  const templates = useMemo(() => {
    let temps = [];
    selectedItem?.templates?.forEach((link) => {
      let words = link.split(".");
      switch (words?.[words.length - 1] ?? "") {
        case "pdf":
          temps.push({
            link,
            image: PDF_LOGO,
          });
          break;
        case "ai":
          temps.push({
            link,
            image: AI_LOGO,
          });
          break;
        case "idml":
          temps.push({
            link,
            image: ID_LOGO,
          });
          break;
        case "psd":
          temps.push({
            link,
            image: PS_LOGO,
          });
          break;
        default:
          break;
      }
    });

    return temps;
  }, [selectedItem?.templates]);

  const price = useMemo(() => {
    return (
      (selectedItem?.price ?? 0) * quantity +
      (selectedDesign ? DESIGN_PRICE : 0)
    ).toFixed(2);
  }, [quantity, selectedDesign, selectedItem]);

  const handleFileChange = useCallback((e) => {
    setFileName(e.target.files?.[0]?.name);
  }, []);

  const onCustomQuantity = useCallback((e) => {
    if (!isProcessing) {
      let value = e.currentTarget.innerText.match(/[0-9]*/)?.[0];
      if (value) {
        setCustomQuantity(value);
        setQuantity(value);

        if (value != e.currentTarget.innerText) {
          e.currentTarget.innerText = value;
        }
      }
    }
  }, []);

  const closeAddedToCartDialog = useCallback(() => {
    setSelectedDesign(null);
    setItemAddedToCart(false);
  }, []);

  const onCustomQuantityFocus = useCallback((e) => {
    if (e.currentTarget.innerText === "Custom Quantity") {
      e.currentTarget.innerText = "";
    }
  }, []);

  const onCustomQuantityFocusOut = useCallback((e) => {
    if (e.currentTarget.innerText === "") {
      e.currentTarget.innerText = "Custom Quantity";
      setCustomQuantity(null);
      if (quantity > 5) setQuantity(1);
    } else {
      let value = parseInt(e.currentTarget.innerText.match(/[0-9]*/)?.[0]);
      if (value < 5) {
        e.currentTarget.innerText = "Custom Quantity";
        setCustomQuantity(null);
        setQuantity(value);
      }
    }
  }, []);

  const onQuantityRowClick = useCallback((e) => {
    if (!isProcessing) {
      let q = parseInt(e.currentTarget.getAttribute("quantity"));
      if (q > 0) setQuantity(q);
    }
  }, []);

  const onItemClick = useCallback(
    (e) => {
      if (isAuthenticated && !isProcessing) {
        let id = e.currentTarget.getAttribute("data-name");
        setSelectedItem(
          product?.productItems?.find((productItem) => productItem.id == id) ??
            null
        );
      }
    },
    [isAuthenticated, isProcessing, product?.productItems]
  );

  const onUploadButtonStateClick = useCallback(() => {
    if (!uploadProgress) setUploadDialogOpen((state) => !state);
  }, [uploadProgress]);

  const onDesignSelectorButtonStateClick = useCallback(() => {
    setDesignSelectDialogOpen((state) => !state);
  }, []);

  const incrementQuantity = useCallback(
    (e) => {
      quantityRef.current.getElementsByTagName("input")[0].value = quantity + 1;
      setQuantity(quantity + 1);
    },
    [quantity, quantityRef]
  );

  const decrementQuantity = useCallback(
    (value) => {
      let val = Math.max(value ?? quantity - 1, 0);
      quantityRef.current.getElementsByTagName("input")[0].value = val;
      setQuantity(val);
    },
    [quantity, quantityRef]
  );

  const onAddToCartClick = useCallback(() => {
    setIsProcessing(true);
    dispatch(
      addItemToCart({ id: selectedItem.id, quantity, item: selectedItem })
    );
    setIsProcessing(false);
    setItemAddedToCart(true);
  }, [dispatch, quantity, selectedItem]);

  const resetForm = useCallback(() => {
    document.querySelector("form").reset();

    document.querySelector('input[name="name"]').value = "";

    setFileName(null);
  }, []);

  const onUploadButtonClick = useCallback(
    (e) => {
      const name = uploadFormRef.current.querySelector('[name="name"]').value;
      const files = uploadFormRef.current.querySelector('[name="file"]').files;

      let formData = new FormData();
      formData.append("name", name);
      formData.append("file", files[0]);

      dispatch(uploadDesign(formData)).then(({ payload }) => {
        onUploadButtonStateClick();
        if (payload?.aspectRatio === selectedItem?.aspectRatio)
          setSelectedDesign(payload);
        resetForm();
      });
    },
    [
      dispatch,
      onUploadButtonStateClick,
      resetForm,
      selectedItem?.aspectRatio,
      uploadFormRef,
    ]
  );

  const quantityFocusOut = useCallback((e) => {
    let val = e.target.value.match(/\d+/g).join("");
    setQuantity(parseInt(val));
  }, []);

  const updateSelectedItem = useCallback(
    (values) => {
      let item = product?.productItems?.find(({ productOptions }) => {
        return productOptions.every(({ name, productOptionItem }) => {
          return !values[name] || values[name] === productOptionItem.name;
        });
      });

      if (!item || selectedItem?.id !== item.id) setSelectedItem(item);
    },
    [product, selectedItem]
  );

  const onValueChange = useCallback(
    (e) => {
      let key = e.target.name;
      let val = e.target.value;

      let values = { ...selectedValues };
      values[key] = val;

      updateSelectedItem(values);
    },
    [selectedValues, updateSelectedItem]
  );

  const onValueImageClicked = useCallback(
    (e) => {
      if (!isProcessing) {
        let key = e.currentTarget.getAttribute("name");
        let val = e.currentTarget.getAttribute("value");

        let values = { ...selectedValues };
        values[key] = val;

        updateSelectedItem(values);
      }
    },
    [isProcessing, selectedValues, updateSelectedItem]
  );

  const eventHandler = useCallback(() => {
    setScrolledToPriceTable(document.scrollingElement.scrollTop > 300 ?? false);
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", eventHandler);

    return () => {
      document.removeEventListener("scroll", eventHandler);
    };
  }, [eventHandler]);

  useEffect(() => {
    let _category = categories?.find((category) => category.id == categoryId);

    let _group = undefined;
    let _product = undefined;
    if (groupId) {
      _group = _category?.productGroups?.find(({ id }) => id == groupId);
      _product = _group?.products?.find((product) => product.id == id);
    } else {
      _product = _category?.products?.find((product) => product.id == id);
    }

    setCategory(_category);
    setGroup(_group);
    setProduct(_product);
    setSelectedItem(_product?.productItems?.[0]);
  }, [categories, categoryId, groupId, id]);

  useEffect(() => {
    let op = {};
    let dp = {};
    selectedItem?.productOptions?.forEach(
      ({ name, displayLabel, productOptionItem }) => {
        op[name] = {
          displayLabel,
          values: [
            { name: productOptionItem.name, image: productOptionItem.preview },
          ],
        };
      }
    );

    product?.productItems?.forEach((item) => {
      let cOpKeys = [];
      item?.productOptions?.forEach(
        ({ name, displayLabel, productOptionItem }) => {
          if (!op[name]) {
            if (dp[name]) {
              if (
                !dp[name].values.some((o) => o.name === productOptionItem.name)
              ) {
                dp[name].values.push({
                  name: productOptionItem.name,
                  image: productOptionItem.preview,
                });
              }
            } else {
              dp[name] = {
                displayLabel,
                values: [
                  {
                    name: productOptionItem.name,
                    image: productOptionItem.preview,
                  },
                ],
              };
            }
          } else {
            if (
              !op[name].values.some((o) => o.name === productOptionItem.name)
            ) {
              op[name].values.push({
                name: productOptionItem.name,
                image: productOptionItem.preview,
              });
            }

            cOpKeys.push(name);
          }
        }
      );

      let moveables = Object.keys(op).filter((key) => !cOpKeys.includes(key));
      moveables.forEach((moveable) => {
        let obj = op[moveable];
        dp[moveable] = obj;
        delete op[moveable];
      });
    });

    setCommonOptions(op);
    setOptions(dp);
  }, [product, selectedItem]);

  useEffect(() => {
    let values = {};
    selectedItem?.productOptions?.forEach(({ name, productOptionItem }) => {
      values[name] = productOptionItem.name;
    });
    setSelectedValues(values);
  }, [selectedItem]);

  useEffect(() => {
    dispatch(getProductCategories());
  }, []);

  const { preview, name, details, productItems } = product ?? {};
  return (
    product && (
      <Container className="px-0" maxWidth="100vw">
        <Container>
          <Box>
            <BackTracker
              paths={[
                {
                  name: category?.name,
                  path: `/productcategory/${category?.id}`,
                },
                group
                  ? {
                      name: group.name,
                      path: `/productgroup/${category?.id}/${group?.id}`,
                    }
                  : {},
              ]}
              end={product.name ?? "Products"}
            />
          </Box>
        </Container>
        <Box className="position-relative d-flex flex-column justify-content-center align-items-center">
          <img
            width={"100%"}
            alt="Preview"
            src={selectedItem?.preview ?? preview}
          />
          <Container
            sx={{
              position: {
                md: scrolledToPriceTable ? "relative" : "absolute",
                xs: "unset",
              },
              top: "0",
            }}
          >
            <Box
              className="p-4"
              sx={{
                position: { md: "absolute", xs: "unset" },
                width: { xs: "100%", md: "570px" },
                backgroundColor: "white",
                right: "0",
              }}
            >
              <Typography
                variant="h4"
                fontFamily={"Poppins,Helvetica,Arial,sans-serif"}
                fontWeight={800}
              >
                {name}
              </Typography>
              <List sx={{ py: "10px" }}>
                {details?.map((detail, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      paddingTop: "4px",
                      paddingBottom: "4px",
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: "30px" }}>
                      <Circle fontSize="10px" />
                    </ListItemIcon>
                    <Typography fontFamily={"Fira Sans"}>{detail}</Typography>
                  </ListItem>
                ))}
              </List>
              {templates?.[0] && (
                <Paper
                  className="p-3"
                  sx={{ backgroundColor: "#eee" }}
                  elevation={2}
                >
                  <Typography fontWeight={600}>Blank Templates:</Typography>
                  <Box className="px-2 pt-2">
                    {templates.map(({ link, image }, index) => (
                      <Link
                        className="m-2"
                        href={link}
                        key={index}
                        download={true}
                      >
                        <img
                          width={40}
                          height={40}
                          src={image}
                          alt="File type"
                        />
                      </Link>
                    ))}
                  </Box>
                </Paper>
              )}
              <Box className="m-2">
                {Object.entries(commonOptions ?? {}).map(
                  ([key, { displayLabel, values }], index) => {
                    return (
                      <Box className="mt-3" key={index}>
                        <Box className="d-flex">
                          <Typography fontWeight={600}>
                            {displayLabel}:
                          </Typography>
                          <Typography className="ms-1">
                            Select an option
                          </Typography>
                        </Box>
                        {values?.[0] && values[0].image ? (
                          <Box className="d-flex flex-row flex-wrap">
                            {values
                              .sort((e1, e2) => e1.name.localeCompare(e2.name))
                              .map(({ name, image }, index) => (
                                <Paper
                                  key={index}
                                  elevation={
                                    selectedValues[key] === name ? 5 : 0
                                  }
                                  className="m-1 border"
                                  sx={{
                                    cursor: "pointer",
                                    borderColor: "#aaa",
                                  }}
                                  onClick={onValueImageClicked}
                                  name={key}
                                  value={name}
                                >
                                  <img
                                    width={40}
                                    height={40}
                                    src={image}
                                    alt={name}
                                  />
                                </Paper>
                              ))}
                          </Box>
                        ) : (
                          <Select
                            size="small"
                            disabled={isProcessing}
                            sx={{ minWidth: "65%" }}
                            name={key}
                            onChange={onValueChange}
                            defaultValue={selectedValues[key]}
                          >
                            {values
                              .sort((e1, e2) => e1.name.localeCompare(e2.name))
                              .map(({ name }, index) => (
                                <MenuItem key={index} value={name}>
                                  {name}
                                </MenuItem>
                              ))}
                          </Select>
                        )}
                      </Box>
                    );
                  }
                )}

                {Object.entries(options ?? {}).map(
                  ([key, { displayLabel, values } = {}], index) => {
                    return (
                      selectedValues[key] && (
                        <Box className="mt-3" key={index}>
                          <Box className="d-flex">
                            <Typography fontWeight={600}>
                              {displayLabel}:
                            </Typography>
                            <Typography className="ms-1">
                              {selectedValues[key] ?? "Select an option"}
                            </Typography>
                          </Box>
                          {values?.[0] && values[0].image ? (
                            <Box className="d-flex flex-row flex-wrap">
                              {values
                                .sort((e1, e2) =>
                                  e1.name.localeCompare(e2.name)
                                )
                                .map(({ name, image }, index) => (
                                  <Paper
                                    key={index}
                                    elevation={
                                      selectedValues[key] === name ? 5 : 0
                                    }
                                    className="m-1 border rounded overflow-hidden"
                                    sx={{
                                      cursor: "pointer",
                                      borderColor: "#aaa",
                                    }}
                                    onClick={onValueImageClicked}
                                    name={key}
                                    value={name}
                                  >
                                    <img
                                      width={50}
                                      height={50}
                                      src={image}
                                      alt={name}
                                    />
                                  </Paper>
                                ))}
                            </Box>
                          ) : (
                            <Select
                              size="small"
                              sx={{ minWidth: "65%" }}
                              name={key}
                              disabled={isProcessing}
                              onChange={onValueChange}
                              defaultValue={selectedValues[key]}
                            >
                              {values
                                .sort((e1, e2) =>
                                  e1.name.localeCompare(e2.name)
                                )
                                .map(({ name }, index) => (
                                  <MenuItem key={index} value={name}>
                                    {name}
                                  </MenuItem>
                                ))}
                            </Select>
                          )}
                        </Box>
                      )
                    );
                  }
                )}
              </Box>
            </Box>
          </Container>
        </Box>
        <Container className="py-4">
          <Box
            sx={{
              width: {
                xs: "100%",
                md: "50%",
              },
            }}
          >
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#ddd" }}>
                    <TableCell align="center">Qty</TableCell>
                    <TableCell align="center">Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      onClick={onQuantityRowClick}
                      quantity={index + 1}
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }}
                    >
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        sx={{
                          backgroundColor:
                            quantity === index + 1
                              ? "var(--alt-color)"
                              : "white",
                          color: quantity === index + 1 ? "white" : "black",
                        }}
                      >
                        <Typography>{index + 1}</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          backgroundColor:
                            quantity === index + 1
                              ? "var(--alt-color)"
                              : "white",
                          color: quantity === index + 1 ? "white" : "black",
                        }}
                      >
                        <Typography>
                          ${(selectedItem?.basePrice * (index + 1)).toFixed(2)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow
                    onClick={onQuantityRowClick}
                    quantity={customQuantity ?? -1}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: customQuantity ? "pointer" : "default",
                    }}
                  >
                    <TableCell
                      width={"50%"}
                      sx={{
                        backgroundColor: customQuantity
                          ? quantity > 5
                            ? "var(--alt-color)"
                            : "white"
                          : "#999",
                        color: quantity > 5 ? "white" : "black",
                      }}
                      align="center"
                    >
                      <Typography
                        align="center"
                        component={"div"}
                        onKeyUp={onCustomQuantity}
                        onFocus={onCustomQuantityFocus}
                        onBlur={onCustomQuantityFocusOut}
                        contentEditable={!isProcessing}
                        sx={{
                          border: "none",
                          outline: "none",
                          ":focus": {
                            outline: "none",
                          },
                          cursor: "text",
                        }}
                      >
                        Custom Quantity
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: customQuantity
                          ? quantity > 5
                            ? "var(--alt-color)"
                            : "white"
                          : "#999",
                        color: quantity > 5 ? "white" : "black",
                      }}
                      align="center"
                    >
                      <Typography>
                        {customQuantity &&
                          `$ ${(
                            customQuantity * selectedItem?.basePrice
                          ).toFixed(2)}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          {quantity && quantity > 0 && (
            <Box
              className="mt-2"
              maxWidth={"50%"}
              display={"flex"}
              alignItems={"flex-end"}
              justifyContent={"center"}
              align="center"
            >
              <Typography className="pb-2">Subtotal</Typography>
              &nbsp;
              <Typography
                fontWeight={800}
                className="pt-0 mt-0"
                fontSize={"2rem"}
              >
                ${(selectedItem?.basePrice * quantity).toFixed(2)}
              </Typography>
              <Chip
                sx={{ fontWeight: "600" }}
                className="ms-2 mb-2"
                label="Exc VAT"
              />
            </Box>
          )}
          {selectedItem && quantity > 0 && (
            <Button
              className="my-1"
              variant="contained"
              sx={{ width: { md: "50%", xs: "100%" }, color: "white" }}
              disabled={isProcessing}
              onClick={onAddToCartClick}
            >
              Add to Cart
            </Button>
          )}
        </Container>
        <Container maxWidth="100vw">
          <ProductContent />
        </Container>
        <AddToCartComplete
          selectedDesign={selectedDesign}
          productName={product?.name}
          isOpen={itemAddedToCart}
          handleClose={closeAddedToCartDialog}
          openUploadDesign={setUploadDialogOpen}
          selectDesignDialogOpen={setDesignSelectDialogOpen}
          aspect={selectedItem?.aspectRatio}
          sx={{ zIndex: "900" }}
        />
        <Designs
          handleClose={onDesignSelectorButtonStateClick}
          open={designSelectDialogOpen}
          onSave={setSelectedDesign}
          currentValue={selectedDesign}
          deletable={false}
          sx={{ zIndex: "1000" }}
          aspect={selectedItem?.aspectRatio}
        />
        <Dialog
          open={uploadDialogOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={onUploadButtonStateClick}
          aria-describedby="alert-dialog-slide-description"
          sx={{ zIndex: "1000" }}
        >
          <DialogTitle>Upload a custom design</DialogTitle>
          <DialogContent>
            <FormControl ref={uploadFormRef} component="form">
              <Grid container m="15px 0" justifyContent="center">
                <Grid item xs={10} md={10} lg={10} p={"10px"}>
                  <TextField
                    sx={{ width: "100%" }}
                    label="Design Name"
                    variant="outlined"
                    name="name"
                    helperText={fileName}
                  />
                </Grid>
                <Grid item xs={5} md={4} lg={10} p={"10px"} pt="0">
                  <Button
                    disabled={!!uploadProgress}
                    component="label"
                    type="button"
                    role={undefined}
                    variant="outlined"
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                    }}
                    tabIndex={-1}
                  >
                    Select file
                    <VisuallyHiddenInput
                      onChange={handleFileChange}
                      disabled={!!uploadProgress}
                      type="file"
                      name="file"
                      accept=".pdf, .jpeg, .jpg, .png"
                    />
                  </Button>
                </Grid>
              </Grid>
              {uploadProgress && (
                <Box>
                  <GradientLinearProgress
                    variant="determinate"
                    value={uploadProgress * 100}
                  />
                </Box>
              )}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={!!uploadProgress}
              onClick={onUploadButtonStateClick}
              variant="contained"
              sx={{ fontFamily: "Poppins", color: "white" }}
            >
              Close
            </Button>
            <Button
              disabled={!!uploadProgress || !fileName}
              onClick={onUploadButtonClick}
              variant="contained"
              sx={{ fontFamily: "Poppins", color: "white" }}
              startIcon={<CloudUploadTwoTone />}
            >
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    )
  );
};

export default Product;
