import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Link,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import { createRef, useCallback, useEffect, useMemo, useState } from "react";
import Carousel from "react-material-ui-carousel";
import "../styles/Home.css";
import { useDispatch, useSelector } from "react-redux";
import { getProductCategories } from "../slices/ProductReducer";
import ProductCategoryBox from "../components/ProductCategoryBox";
import BackTracker from "../components/BackTracker";
import zIndex from "@mui/material/styles/zIndex";
import IMG1 from "../images/img_01.png";
import IMG3 from "../images/img_03.png";
import { Business, People, Search } from "@mui/icons-material";

const Home = () => {
  const dispatch = useDispatch();

  const { categories, isProductCategoryProcessing } = useSelector(
    (state) => state.product
  );

  const productRef = createRef();

  const slideContent = useMemo(() => {
    return [
      {
        title: "Online Printing",
        content: "The Online Print Shop Dedicated to Your Success",
        image: IMG1,
        link: "/productcategory",
      },
      {
        title: "Online Printing",
        content: "The Online Print Shop Dedicated to Your Success",
        image: IMG3,
        link: "/productcategory",
      },
    ];
  }, []);

  const detailsContent = useMemo(() => {
    return [
      {
        content: "Award-winning client service support",
        image:
          "https://factoryofstickers.com/cdn/shop/files/Icons-01_160x160@2x.png?v=1613779277",
      },
      {
        content: "Sustainable paper sourced from FSC certified forests",
        image:
          "https://factoryofstickers.com/cdn/shop/products/large-business-card-1_360x.jpg?v=1587930405",
      },
      {
        content: "Free artwork check with every order as standard",
        image:
          "https://factoryofstickers.com/cdn/shop/products/USA-Product-Catalog-2016_360x.jpg",
      },
      {
        content: "Flexible delivery options to suit your needs",
        image:
          "https://factoryofstickers.com/cdn/shop/products/large-sign-1_2511ffc1-4660-4367-94e5-91f9d32baec4_360x.jpg?v=1665850801",
      },
      {
        content: "We print for businesses of every size",
        image:
          "https://factoryofstickers.com/cdn/shop/products/213670217x5_360x.jpg?v=1666036885",
      },
    ];
  }, []);

  const onShopNowClick = useCallback(() => {
    productRef?.current?.scrollIntoViewIfNeeded();
  }, [productRef]);

  useEffect(() => {
    dispatch(getProductCategories());
  }, []);

  return (
    <Container
      maxWidth="100%"
      sx={{
        paddingLeft: "0 !important",
        paddingRight: "0 !important",
      }}
    >
      <Carousel
        autoPlay
        indicatorIconButtonProps={{
          style: {
            margin: "5px 3px 20px 3px",
            fill: "#eee",
          },
        }}
        indicatorContainerProps={{
          sx: { position: "absolute", zIndex: 40, bottom: 0 },
        }}
        activeIndicatorIconButtonProps={{
          sx: {
            backgroundColor: "white",
            ":hover": { backgroundColor: "white" },
          },
        }}
      >
        {slideContent.map(({ title, content, buttons, image, slug }, index) => {
          return (
            <Box
              sx={{
                height: {
                  backgroundColor: "whitesmoke",
                  md: "500px",
                },
              }}
              key={index}
              display={"flex"}
            >
              <Container
                sx={{
                  backgroundImage: `url(${image})`,
                  backgroundPositionX: "right",
                  backgroundSize: "auto 100%",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="py-3 pt-md-10 pb-md-10 h-md-100">
                  <Typography
                    className="mb-3 my-md-3"
                    variant="h2"
                    sx={{
                      color: "black",
                      fontSize: "48px",
                      fontFamily: "Montserrat Variable",
                      fontWeight: "700",
                      width: "40%",
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    className="mb-3 my-md-3"
                    sx={{
                      color: "black",
                      fontSize: "30px",
                      fontFamily: "Montserrat Variable",
                      fontWeight: "600",
                      width: "40%",
                    }}
                  >
                    {content}
                  </Typography>
                  <Button variant="contained" onClick={onShopNowClick}>
                    <Typography
                      sx={{
                        color: "White",
                        p: ".325rem 0.875rem",
                        fontSize: "1rem",
                        fontFamily: "Poppins",
                        textTransform: "none",
                      }}
                    >
                      Shop Now
                    </Typography>
                  </Button>
                </div>
              </Container>
            </Box>
          );
        })}
      </Carousel>
      <Container>
        <Box className="d-flex px-5" justifyContent={"space-between"}>
          <Typography
            className="my-2 pr-0 pl-0 p-2 w100 text-center"
            sx={{ fontWeight: 900, fontSize: ".825rem" }}
          >
            <People
              sx={{
                mr: "8px",
                display: { md: "unset", xs: "none" },
                color: "var(--main-color)",
              }}
            />
            Friendly print experts
          </Typography>
          <Typography
            className="my-2 pr-0 pl-0 p-2 w100 text-center"
            sx={{ fontWeight: 900, fontSize: ".825rem" }}
          >
            <Search
              sx={{
                mr: "5px",
                display: { md: "unset", xs: "none" },
                color: "var(--main-color)",
              }}
            />
            We Check Every Piece of Artwork
          </Typography>
          <Typography
            className="my-2 pr-0 pl-0 p-2 w100 text-center"
            sx={{ fontWeight: 900, fontSize: ".825rem" }}
          >
            <Business
              sx={{
                mr: "5px",
                color: "var(--main-color)",
                display: { md: "unset", xs: "none" },
              }}
            />
            Largest US-based online printer
          </Typography>
        </Box>
      </Container>
      <Divider sx={{ height: "15px", backgroundColor: "#eee" }}></Divider>
      <Container>
        <Typography></Typography>
      </Container>
      <Box>
        <Typography
          variant="h4"
          align="center"
          m="35px 15px"
          fontFamily="Poppins"
        >
          High Quality Online Printing Services
        </Typography>
      </Box>
      {isProductCategoryProcessing && (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      <Box maxWidth="100vw" align="center" m="30px 0" ref={productRef}>
        <Grid
          justifyContent="center"
          maxWidth="lg"
          width="100%"
          container
          columnSpacing={2}
          rowSpacing={2}
        >
          {!isProductCategoryProcessing &&
            categories.map(({ id, name, description, preview }, index) => (
              <Grid item lg={4} md={6} xs={8} key={index}>
                <ProductCategoryBox
                  name={name}
                  description={description}
                  preview={preview}
                  link={id}
                />
              </Grid>
            ))}
        </Grid>
      </Box>
      <Box>
        <Typography
          variant="h4"
          align="center"
          m="35px 15px"
          fontFamily="Poppins"
        >
          The Online Print Shop Dedicated to Your Success
        </Typography>
      </Box>
      <Container>
        <Grid container className="my-4 justify-content-center">
          {detailsContent.map(({ image, content }) => {
            return (
              <Grid item xs={5} sm={4} md={3} lg={2} p={"5px 10px"}>
                <Box className="d-flex justify-content-center align-items-center flex-column">
                  <img
                    className="rounded-circle"
                    width={120}
                    height={120}
                    src={image}
                    alt="Content detail"
                  />
                  <Typography
                    className="mt-3"
                    sx={{
                      fontSize: ".875rem",
                      textAlign: "center",
                    }}
                  >
                    {content}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Container>

      {/*<Box>
        <Typography
          variant="h4"
          align="center"
          m="35px 15px"
          fontFamily="Poppins"
        >
          Featured Print Deals & News
        </Typography>
      </Box>*/}

      <Container></Container>
    </Container>
  );
};

export default Home;
