import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addNotification } from "./ProcessReducer";
import { fetchProductCategories } from "../api/requests";

const getProductCategories = createAsyncThunk(
  "product/getProductCategories",
  async (_, { dispatch, rejectWithValue, getState }) => {
    dispatch(setProductCategoryProcessing(true));
    try {
      const { categories } = getState().product;

      if (!categories || categories.length === 0) {
        let { data } = await fetchProductCategories();
        dispatch(setProductCategoryProcessing(false));
        return data;
      } else return categories;
    } catch (error) {
      dispatch(setProductCategoryProcessing(false));
      const { message } = error.response?.data || error;
      dispatch(
        addNotification({
          message: message ?? error.response?.data ?? error,
          type: "error",
        })
      );
      rejectWithValue(error);
    }
  }
);

const initialState = {
  isProductCategoryProcessing: false,
  categories: [],
};

const slice = createSlice({
  name: "ProductSlice",
  initialState: initialState,
  reducers: {
    setProductCategoryProcessing: (state, { payload }) => {
      state.isProductCategoryProcessing = payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getProductCategories.fulfilled, (state, { payload = [] }) => {
        state.categories = payload;
        state.isProductCategoryProcessing = false;
      })
      .addCase(getProductCategories.rejected, (state, { payload = [] }) => {
        state.isProductCategoryProcessing = false;
      }),
});

const { setProductCategoryProcessing } = slice.actions;

export { getProductCategories };

export default slice.reducer;
