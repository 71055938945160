import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { Delete, Download } from "@mui/icons-material";
import { SERVER, STATIC_URI } from "../api/api_routes";
import { executeDeleteDesign } from "../slices/DesignReducer";

const Designs = ({
  open,
  handleClose,
  currentValue,
  onSave,
  deletable = true,
  aspect,
  ...rest
}) => {
  const dispatch = useDispatch();

  const { designs } = useSelector((state) => state.design);

  const [selectedValue, setSelectedValue] = useState(currentValue);

  const onSelection = useCallback(
    (e) => {
      let id = e.currentTarget.getAttribute("data-id");
      setSelectedValue(designs.find((design) => design.id == id));
    },
    [designs]
  );

  const deleteDesign = useCallback(
    (e) => {
      let id = e.currentTarget.getAttribute("data-id");
      dispatch(executeDeleteDesign(id));
    },
    [dispatch]
  );

  const onSaveClick = useCallback(() => {
    onSave(selectedValue);
    handleClose();
  }, [handleClose, onSave, selectedValue]);

  useEffect(() => {
    setSelectedValue(currentValue);
  }, [currentValue]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      {...rest}
    >
      <DialogTitle
        fontFamily={"Poppins"}
        sx={{ backgroundColor: "#eee", boxShadow: "0 0 3px black" }}
        id="responsive-dialog-title"
      >
        Select a design{selectedValue && `: ${selectedValue.name}`}
      </DialogTitle>
      <DialogContent>
        <Grid justifyContent="center" container>
          {designs
            ?.filter(({ aspectRatio }) => aspectRatio === aspect)
            .map(({ id, name, source, previews }) => {
              return (
                <Grid lg={6} md={6} xs={10} item key={id}>
                  <Card
                    sx={{
                      cursor: "pointer",
                      flexDirection: "row !important",
                      m: "15px",
                      background:
                        selectedValue?.id == id &&
                        "linear-gradient(to bottom right, #fa1232, #122355)",
                      color: selectedValue?.id == id && "white",
                    }}
                    data-id={id}
                    onClick={onSelection}
                  >
                    {previews?.[0] && (
                      <CardMedia
                        component="img"
                        alt="Preview"
                        src={`${STATIC_URI}${previews[0]}`}
                      />
                    )}
                    <CardContent sx={{ paddingBottom: "16px !important" }}>
                      <Box
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between !important"
                      >
                        <Typography width="fit-content">{name}</Typography>
                        <Box>
                          <IconButton
                            LinkComponent={Link}
                            dowload="true"
                            href={`${STATIC_URI}${source}`}
                          >
                            <Download
                              sx={{
                                color:
                                  selectedValue?.id == id ? "white" : "black",
                              }}
                            />
                          </IconButton>
                          {deletable && (
                            <IconButton
                              data-id={id}
                              dowload="true"
                              onClick={deleteDesign}
                            >
                              <Delete
                                sx={{
                                  color:
                                    selectedValue?.id == id ? "white" : "black",
                                }}
                              />
                            </IconButton>
                          )}
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "#eee" }}>
        <Button
          autoFocus
          onClick={handleClose}
          variant="contained"
          sx={{ fontFamily: "Poppins", color: "white" }}
        >
          Close
        </Button>
        <Button
          disabled={!selectedValue}
          onClick={onSaveClick}
          variant="contained"
          sx={{ fontFamily: "Poppins", color: "white" }}
          autoFocus
        >
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Designs;
