import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { router, routes } from "../routes";
import {
  Badge,
  ButtonGroup,
  CircularProgress,
  Collapse,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  LinearProgress,
  Link,
  List,
  SwipeableDrawer,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../slices/AuthReducer";
import {
  ArrowDownward,
  Close,
  LocalPhone,
  Person,
  PersonOutline,
  Search,
  ShoppingCart,
  ShoppingCartCheckout,
} from "@mui/icons-material";
import DrawerCartItem from "./DrawerCartItem";
import { checkout } from "../slices/OrderReducer";
import Require from "./Required";
import ITextField from "./components/ITextField";
import {
  Nav,
  NavItem,
  NavbarBrand,
  NavbarText,
  NavbarToggler,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductBox from "./ProductBox";
import Logo from "../images/Sayyes Digital Printing Logo 1x2.gif";
import MobileLogo from "../images/Sayyes Digital Printing 1x1.gif";
import CheckoutComplete from "./CheckoutComplete";
import FilteredProducts from "./FilteredProducts";
import { SERVER } from "../api/api_routes";

const Navbar = () => {
  const dispatch = useDispatch();

  const { isAuthenticated, user, isProcessing } = useSelector(
    (state) => state.auth
  );
  const { cart, price } = useSelector((state) => state.cart);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [CheckoutCompleted, setCheckoutCompleted] = React.useState(false);
  const [cartOpen, setCartOpen] = React.useState(false);
  const [checkoutOnGoing, setCheckoutOnGoing] = React.useState(false);
  const [isProductsBoxOpen, setProductsBoxOpen] = React.useState(false);
  const [isNavbarMobOpen, setNavbarMobOpen] = React.useState(false);
  const [orderId, setOrderId] = React.useState(null);

  const handleCheckoutCompleteClicked = React.useCallback(() => {
    setCheckoutCompleted((state) => !state);
    setOrderId(null);
  });

  const payNow = () => {
    if (orderId && user?.email) {
      let searchParams = new URLSearchParams();
      searchParams.set("orderId", orderId);
      searchParams.set("email", user?.email);

      window.location = `${SERVER}/checkout?${searchParams.toString()}`;
    }
  };

  const handleEnterProducts = React.useCallback(() => {
    setProductsBoxOpen(true);
  }, []);

  const handleExitProducts = React.useCallback(() => {
    setProductsBoxOpen(false);
  }, []);

  const onCartButtonClick = React.useCallback(() => {
    setCartOpen((state) => !state);
  }, []);

  const logoutAction = React.useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const settings = React.useMemo(() => {
    return [{ name: "Logout", action: logoutAction }];
  }, [logoutAction]);

  const handleOpenNavMenu = React.useCallback((event) => {
    setAnchorElNav(event.currentTarget);
  }, []);

  const handleOpenUserMenu = React.useCallback((event) => {
    setAnchorElUser(event.currentTarget);
  }, []);

  const navigate = React.useCallback((e) => {
    router.navigate(e.currentTarget.getAttribute("to"));
    handleCloseNavMenu();
    setNavbarMobOpen(false);
  }, []);

  const onCheckoutClick = React.useCallback(() => {
    if (!isAuthenticated) {
      router.navigate("/login");
      onCartButtonClick();
      return;
    }

    setCheckoutOnGoing(true);
    dispatch(checkout(cart))
      .then((e) => {
        setOrderId(e.payload?.id);
        setCheckoutCompleted(true);
      })
      .finally(() => {
        setCheckoutOnGoing(false);
      });
  }, [cart, dispatch, isAuthenticated, onCartButtonClick]);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = React.useCallback(() => {
    setIsOpen((state) => !state);
  }, []);

  const handleNavbarMoba = () => setNavbarMobOpen((state) => !state);

  return (
    <AppBar
      onMouseLeave={handleExitProducts}
      position="sticky"
      sx={{
        top: 0,
        zIndex: 100,
        justifyContent: "center",
        backgroundColor: "white",
      }}
    >
      <Container>
        <Toolbar disableGutters>
          <Box display={"flex"} flexDirection={"column"} width={"100%"}>
            <Box
              className="p-3 d-flex align-items-center justify-contents-center"
              flexDirection={"row"}
              width={"100%"}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{
                  display: {
                    xs: "unset",
                    md: "none",
                  },
                  width: "52px",
                  height: "52px",
                  mr: 1,
                }}
                onClick={handleNavbarMoba}
              >
                {isNavbarMobOpen ? <Close /> : <MenuIcon />}
              </IconButton>
              <Box
                to="/"
                onClick={navigate}
                width={160}
                sx={{
                  cursor: "pointer",
                  display: { md: "flex", xs: "none" },
                }}
              >
                <img width={"100%"} src={Logo} alt="landscape logo" />
              </Box>
              <Box
                to="/"
                onClick={navigate}
                width={{ sm: "100px", xs: "70px" }}
                sx={{ cursor: "pointer", display: { md: "none", xs: "flex" } }}
              >
                <img width={"100%"} src={MobileLogo} alt="mobile logo" />
              </Box>
              <Box
                className="position-relative"
                sx={{
                  width: "80%",
                  display: { md: "flex", xs: "none" },
                }}
              >
                <ITextField
                  className="p-3"
                  inputSx={{
                    fontSize: "12px",
                    color: "#484d53",
                    padding: ".375rem .75rem",
                    border: "1px solid transparent",
                  }}
                  sx={{ width: "100%" }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={{ p: "0 5px" }}>
                        <Search color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Tooltip sx={{ marginLeft: "auto" }} title="Account">
                <IconButton to="/profile" onClick={navigate}>
                  <Person fontSize="large" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Cart">
                <IconButton onClick={onCartButtonClick}>
                  <Badge
                    badgeContent={
                      <Typography
                        fontWeight={"bold"}
                        fontFamily={"Nunito Sans Variable"}
                        sx={{ color: "white" }}
                      >
                        {cart?.length ?? 0}
                      </Typography>
                    }
                    color="primary"
                  >
                    <ShoppingCart fontSize="large" />
                  </Badge>
                </IconButton>
              </Tooltip>
            </Box>
            <Box
              className="pb-3 "
              flexDirection={"row"}
              width={"100%"}
              padding={"0 15px"}
              justifyContent={"space-between"}
              display={{
                xs: "none",
                md: "flex",
              }}
            >
              <Box display={"flex"} flexDirection={"row"}>
                {routes.map(({ nav, name, path, link, auth, shopAll }) => {
                  return (
                    nav &&
                    (auth ? (
                      <Require key={name} authorized={isAuthenticated}>
                        <Link
                          onMouseEnter={
                            shopAll ? handleEnterProducts : handleExitProducts
                          }
                          onMouseLeave={
                            shopAll ? undefined : handleExitProducts
                          }
                          to={path}
                          onClick={navigate}
                          className="mx-1"
                          sx={{
                            textDecoration: "none",
                            color: "black",
                            cursor: "pointer",
                            ":hover::after": {
                              borderColor: "var(--main-color) !important",
                            },
                            ":hover .link-text": {
                              color: "var(--main-color)",
                            },
                            ":after": {
                              content: '""',
                              display: "block",
                              borderBottom: "2px solid transparent",
                              transition:
                                "0.15s cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                            },
                            ":visited": { textDecoration: "none" },
                          }}
                        >
                          <Typography
                            sx={{
                              transition:
                                "0.15s cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                            }}
                            fontSize={".875rem"}
                            fontFamily={"Poppins"}
                            className="link-text px-1"
                            fontWeight={550}
                          >
                            {name}
                          </Typography>
                        </Link>
                      </Require>
                    ) : (
                      <Link
                        onMouseEnter={shopAll ? handleEnterProducts : undefined}
                        onMouseLeave={shopAll ? undefined : handleExitProducts}
                        to={path}
                        onClick={navigate}
                        className="mx-1"
                        sx={{
                          textDecoration: "none",
                          color: "black",
                          cursor: "pointer",
                          ":hover::after": {
                            borderColor: "var(--main-color) !important",
                          },
                          ":hover .link-text": {
                            color: "var(--main-color)",
                          },
                          ":after": {
                            content: '""',
                            display: "block",
                            borderBottom: "2px solid transparent",
                            transition:
                              "0.15s cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                          },
                          ":visited": { textDecoration: "none" },
                        }}
                      >
                        <Typography
                          sx={{
                            transition:
                              "0.15s cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                          }}
                          fontSize={".875rem"}
                          fontFamily={"Poppins"}
                          className="link-text px-1"
                          fontWeight={550}
                        >
                          {name}
                        </Typography>
                      </Link>
                    ))
                  );
                })}
              </Box>
              <Box>
                <Button
                  sx={{
                    backgroundColor: "var(--alt-color)",
                    borderColor: "var(--alt-color)",
                    color: "white",
                    ":hover": {
                      backgroundColor: "var(--alt-shadow-color)",
                      borderColor: "var(--alt-shadow-color)",
                    },
                    fontSize: ".775rem",
                  }}
                  startIcon={<LocalPhone className="phone-nav-icon" />}
                  LinkComponent={Link}
                  href={"tel:16105975438"}
                >
                  1 610 597 5438
                </Button>
              </Box>
            </Box>
          </Box>
        </Toolbar>

        {isNavbarMobOpen && (
          <Box className="moba-link-container">
            {routes.map(({ nav, name, path, link, auth, shopAll }) => {
              return (
                nav &&
                (auth ? (
                  <Require key={name} authorized={isAuthenticated}>
                    <Link
                      to={path}
                      onClick={navigate}
                      className="mx-1"
                      sx={{
                        textDecoration: "none",
                        color: "black",
                        cursor: "pointer",
                        ":hover": {
                          color: "var(--main-color)",
                        },
                        ":visited": { textDecoration: "none" },
                      }}
                    >
                      <Typography
                        sx={{
                          transition: "0.15s cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        }}
                        fontSize={".875rem"}
                        fontFamily={"Poppins"}
                        className="link-text px-1"
                        fontWeight={550}
                      >
                        {name}
                      </Typography>
                    </Link>
                  </Require>
                ) : (
                  <Link
                    to={path}
                    onClick={navigate}
                    className="mx-1"
                    sx={{
                      textDecoration: "none",
                      color: "black",
                      cursor: "pointer",
                      ":hover": {
                        color: "var(--main-color)",
                      },
                      ":visited": { textDecoration: "none" },
                    }}
                  >
                    <Typography
                      sx={{
                        transition: "0.15s cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                      }}
                      fontSize={".875rem"}
                      fontFamily={"Poppins"}
                      className="link-text px-1"
                      fontWeight={550}
                    >
                      {name}
                    </Typography>
                  </Link>
                ))
              );
            })}
          </Box>
        )}

        <ProductBox
          onMouseLeave={handleExitProducts}
          isOpen={isProductsBoxOpen}
          handleClose={handleExitProducts}
        />
      </Container>

      <SwipeableDrawer
        anchor="right"
        open={cartOpen}
        onOpen={onCartButtonClick}
        onClose={onCartButtonClick}
        variant="temporary"
        className="d-flex"
      >
        <>
          <Box
            sx={{
              width: {
                lg: "500px",
                md: "400px",
                sm: "300px",
                xs: "min(300px, 100vw)",
              },
              maxWidth: "100%",
              flexGrow: 1,
            }}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              p={"20px 5px"}
              sx={{
                boxShadow: "0 0 3px black",
                backgroundColor: "#eee",
              }}
            >
              <IconButton onClick={onCartButtonClick}>
                <ArrowDownward
                  sx={{
                    transform: cartOpen ? "rotateZ(90deg)" : "none",
                    transition: "0.3s ease",
                  }}
                />
              </IconButton>
              <Typography variant="h5" textAlign="left" fontFamily={"Poppins"}>
                Cart
              </Typography>
            </Box>
            {cart?.[0] ? (
              <List>
                {cart?.map(({ item, itemId, quantity, design }, index) => {
                  return (
                    <DrawerCartItem
                      disabled={checkoutOnGoing}
                      design={design}
                      id={itemId}
                      productItem={item}
                      quantity={quantity}
                      key={index}
                    />
                  );
                })}
              </List>
            ) : (
              <Box>
                <Typography className="m-4" align="center">
                  Your cart is empty.
                </Typography>
              </Box>
            )}
          </Box>

          {cart?.[0] && (
            <Box position="sticky" sx={{ bottom: 0, backgroundColor: "#eee" }}>
              {checkoutOnGoing && <LinearProgress sx={{ width: "100%" }} />}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: "8px 20px",
                }}
              >
                <Typography variant="h6" fontFamily={"Poppins"}>
                  Total Price
                </Typography>
                <Typography variant="h6" fontFamily={"Poppins"}>
                  ${price?.toFixed(2) ?? "0.00"}
                </Typography>
              </Box>
              <Box sx={{ p: "8px 20px" }}>
                {cart?.[0] && (
                  <Button
                    disabled={checkoutOnGoing}
                    onClick={onCheckoutClick}
                    variant="contained"
                    startIcon={<ShoppingCartCheckout />}
                    sx={{ color: "white" }}
                  >
                    Checkout
                  </Button>
                )}
              </Box>
            </Box>
          )}
        </>
      </SwipeableDrawer>

      <CheckoutComplete
        open={CheckoutCompleted}
        handleClose={handleCheckoutCompleteClicked}
        completePayment={payNow}
      />
    </AppBar>
  );
};
export default Navbar;
