import { Box, Tab, Tabs } from "@mui/material";
import { useCallback, useState } from "react";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const ProductContent = ({ product }) => {
  const [value, setValue] = useState(0);

  const handleChange = useCallback((e) => {
    setValue(parseInt(e.currentTarget.getAttribute("data-value")));
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Item One" data-value={0} />
          <Tab label="Item Two" data-value={1} />
          <Tab label="Item Three" data-value={2} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        Item One 1
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        Item Two 1
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        Item Three 1
      </CustomTabPanel> */}
    </Box>
  );
};

export default ProductContent;
